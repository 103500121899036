import React from 'react';
import clsx from 'clsx';
import {
  Checkbox as BaseCheckbox,
  CheckboxProps as BaseCheckboxProps,
} from '@headlessui/react';
import { CheckIcon, MinusIcon } from '../icons';

export enum CheckboxColors {
  Purple = 'Purple',
  Blue = 'Blue',
  Gray = 'Gray',
  Green = 'Green',
  Red = 'Red',
}
type CheckboxProps = {
  checked?: boolean;
  color?: CheckboxColors;
} & BaseCheckboxProps;

const ColorScheme = {
  [CheckboxColors.Purple]: {
    checked: ['bg-purple-900', 'border-purple-900', 'text-white'],
    indeterminate: ['bg-purple-500', 'border-purple-500', 'text-white'],
    unchecked: ['border-gray-300'],
    disabled: ['text-gray-600', 'bg-gray-300', 'border-gray-300'],
  },
  [CheckboxColors.Red]: {
    checked: ['bg-red-500', 'border-red-500', 'text-white'],
    indeterminate: ['bg-red-300', 'border-red-300', 'text-white'],
    unchecked: ['border-gray-300'],
    disabled: ['text-gray-600', 'bg-gray-300', 'border-gray-300'],
  },
  [CheckboxColors.Blue]: {
    checked: ['bg-blue-600', 'border-blue-600', 'text-white'],
    indeterminate: ['bg-blue-400', 'border-blue-400', 'text-white'],
    unchecked: ['border-gray-300'],
    disabled: ['text-gray-600', 'bg-gray-300', 'border-gray-300'],
  },
  [CheckboxColors.Gray]: {
    checked: ['bg-gray-900', 'border-gray-900', 'text-white'],
    indeterminate: ['bg-gray-700', 'border-gray-700', 'text-white'],
    unchecked: ['border-gray-300'],
    disabled: ['text-gray-600', 'bg-gray-300', 'border-gray-300'],
  },
  [CheckboxColors.Green]: {
    checked: ['bg-green-500', 'border-green-500', 'text-white'],
    indeterminate: ['bg-green-500', 'border-green-500', 'text-white'],
    unchecked: ['border-gray-300'],
    disabled: ['text-gray-600', 'bg-gray-300', 'border-gray-300'],
  },
};

export const Checkbox = ({
  color = CheckboxColors.Gray,
  ...otherProps
}: CheckboxProps) => {
  return (
    <BaseCheckbox {...otherProps}>
      {({ checked, disabled, indeterminate }) => (
        <span
          className={clsx(
            'group flex items-center justify-center rounded-lg w-6 h-6 border-2 text-white',
            ColorScheme[color].unchecked,
            !indeterminate && checked && ColorScheme[color].checked,
            disabled && ColorScheme[color].disabled,
            indeterminate && ColorScheme[color].indeterminate,
          )}
        >
          {indeterminate && <MinusIcon /> || <CheckIcon />}
        </span>
      )}
    </BaseCheckbox>
  );
};
