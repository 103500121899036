import {
  CompanyFieldsFragment,
  useCompanyUpdateMutation,
} from 'graphql/generated';
import React, { useEffect, useState } from 'react';
import { useToast } from '../../layout/Toast';
import { useProjectContext } from '../../../providers/ProjectContextProvider';
import { useNavigate } from 'react-router-dom';
import { CompanyLogoInput } from './CompanyLogoInput';
import { DeleteCompanyButton } from './DeleteCompanyButton';

import { AppRoutes } from '../../../screens/AppRoutes';
import { useTranslation } from '@hooks/useTranslation';

export function TemplateCompany({
  company,
}: {
  company: CompanyFieldsFragment;
}) {
  const { t } = useTranslation();
  const toast = useToast();
  const projectContext = useProjectContext();
  const navigate = useNavigate();

  const [updateCompanyMutation] = useCompanyUpdateMutation();

  const [name, setName] = useState<string>(company.name);

  useEffect(() => {
    setName(company.name);
  }, [company]);

  const saveCompany = () => {
    updateCompanyMutation({
      variables: {
        input: {
          id: company.id,
          name,
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage(t('toast:template.project.update.success'));
        projectContext?.refreshCompany();
      })
      .catch((err) => {
        toast.openToastWithError(err.message);
      });
  };

  return (
    <div className="space-y-4 w-full max-w-5xl">
      <div className="space-y-8 divide-y divide-gray-100">
        <div className="flex space-x-8 divide-x divide-gray-100 w-full">
          <div className="flex flex-col gap-2 w-1/2">
            <label htmlFor="name" className="form-input-label">
              {t('template.project.company.name')}
            </label>
            <div className="flex items-center gap-2 justify-between">
              <input
                type="text"
                name="name"
                id="name"
                className="form-input-text"
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
              <button className="primary small" onClick={saveCompany}>
                {t('global:save')}
              </button>
            </div>
          </div>
          <div className="pl-8 w-1/2">
            <CompanyLogoInput company={company} />
          </div>
        </div>
        <div className="py-8">
          <DeleteCompanyButton
            company={company}
            callback={() => navigate(AppRoutes.Home)}
          />
        </div>
      </div>
    </div>
  );
}
