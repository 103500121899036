import React from 'react';
import { LoaderFullscreen } from '../components/layout/Loader';
import { HomeScreen } from '../screens/project/home/HomeScreen';
import { useProjectContext } from './ProjectContextProvider';
import { useCurrentUser } from './CurrentUserProvider';
import { isCoachUser } from '../services/CompanyService';

type ProjectContextRequiredProps = {
  requiredCompany: boolean;
};

export const withProjectContext = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
  options?: ProjectContextRequiredProps,
) => {
  return function WithProContext(props: P) {
    const projectContext = useProjectContext();
    const currentUser = useCurrentUser();
    const isCoach = isCoachUser(currentUser);

    if (projectContext?.isLoadingCompany) {
      return (
        <div className="w-full h-screen bg-white">
          {projectContext?.isLoadingCompany}
          <LoaderFullscreen />
        </div>
      );
    }

    if (options?.requiredCompany
        && ((isCoach && !projectContext?.isLoadingCompany && !projectContext?.company)
          || !currentUser?.company
        )) {
      return <HomeScreen />;
    }

    return <WrappedComponent {...props} />;
  };
};
