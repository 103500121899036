import React from 'react';
import {
  ChooseReportDisclosureRequirementsRow,
  SelectedData,
} from './useChooseReportDisclosureRequirementsColumns';
import { Td } from '../../../../molecules/table/Td';
import { Checkbox, CheckboxColors } from '../../../../form/Checkbox';
import { GapAnalysisSummary_DisclosureRequirementFragment } from '../../../../../graphql/cms/generated';
import { useTranslation } from '@hooks/useTranslation';
import { getNumberOfDatapoints } from '../useGapAnalysisTopicDRTableColumns';

export const SummaryDetailDisclosureRequirementsRow = ({
  data: { disclosureRequirements, topic },
  columnLength,
  toggleDisclosureRequirementSelection,
  selectedData,
}: {
  data: ChooseReportDisclosureRequirementsRow;
  columnLength: number;
  toggleDisclosureRequirementSelection: (
    topicReferenceSlug: string,
    referenceSlug: string,
  ) => void;
  selectedData: SelectedData;
}) => {
  return (
    <tr className={'bg-gray-50'}>
      <Td colSpan={columnLength}>
        <div className="flex flex-col items-left ml-9 gap-2">
          {disclosureRequirements.map((disclosureRequirement) => (
            <DisclosureRequirementRow
              key={disclosureRequirement.documentId}
              topicReferenceSlug={topic.slug}
              disclosureRequirement={disclosureRequirement}
              toggleDisclosureRequirementSelection={
                toggleDisclosureRequirementSelection
              }
              selectedData={selectedData}
            />
          ))}
        </div>
      </Td>
    </tr>
  );
};

type DisclosureRequirementRowProps = {
  topicReferenceSlug: string;
  disclosureRequirement: GapAnalysisSummary_DisclosureRequirementFragment;
  toggleDisclosureRequirementSelection: (topicReferenceSlug: string, referenceSlug: string) => void;
  selectedData: SelectedData;
};

const DisclosureRequirementRow = ({
      topicReferenceSlug,
      disclosureRequirement,
      toggleDisclosureRequirementSelection,
      selectedData,
    }: DisclosureRequirementRowProps) => {
  const isChecked = selectedData[topicReferenceSlug]?.disclosureRequirements.includes(
    disclosureRequirement.slug!,
  );
  const { t } = useTranslation();
  const numberOfDatapoints = getNumberOfDatapoints(disclosureRequirement);

  return (
    <div className="flex items-center gap-2">
      <Checkbox
        color={CheckboxColors.Purple}
        checked={isChecked}
        onChange={() =>
          toggleDisclosureRequirementSelection(
            topicReferenceSlug,
            disclosureRequirement.slug!,
          )
        }
      />
      <span>
        {disclosureRequirement.title}
      </span>
      <span className={'text-gray-300'}>
        {t('gap_analysis.chooseReportTopicModal.table.header.disclosureRequirements.numberOfDatapoints', {
          count: numberOfDatapoints,
        })}
      </span>
    </div>
  );
};



