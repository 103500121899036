import React, { useState } from 'react';
import { useTranslation } from '@hooks/useTranslation';
import messageIllustration from '../../../../assets/images/illustrations/diagnostic/message.png';
import { Link } from 'react-router-dom';
import { Table } from '../../../molecules/table/Table';
import { IroSummaryFilters } from './IroSummaryFilters';
import {
  IroSummaryStakeRow,
  useIroSummaryColumns,
} from './useIroSummaryColumns';
import {
  ProIroSummaryScreenFragment,
  ProIroSummaryScreenScoresFragment,
} from '../../../../graphql/generated';
import { transformData } from './transformData';
import { getSortedRowModel } from '@tanstack/react-table';
import { SummaryDetailIroRow } from './SummaryDetailIroRow';
import { MessageIcon } from '../../../icons';
import { IllustratedExplanationMessage } from '../../../generic/IllustratedExplanationMessage';
import { MaterialityThresholdButton } from '../../materiality/threshold/MaterialityThresholdButton';
import clsx from 'clsx';
import { AppRoutes } from '../../../../screens/AppRoutes';

export const IroSummary = ({
  referential,
  scores,
}: {
  referential: ProIroSummaryScreenFragment;
  scores: ProIroSummaryScreenScoresFragment[];
}) => {
  const { t } = useTranslation();
  const [currentPillarId, setCurrentPillarId] = useState<string | null>(null);
  const columns = useIroSummaryColumns();
  const data = transformData(
    referential.pillars,
    scores,
    currentPillarId
      ? {
          pillarId: [currentPillarId],
        }
      : undefined,
  );

  const getCellClassName = (row: IroSummaryStakeRow, columnId: string) =>
    clsx('text-base', {
      '!border-l-4 !border-l-purple-500 bg-gradient-to-r from-purple-100 to-transparent':
        !row.stake.isDisabled && columnId === 'stake', // Appliquez les classes uniquement à la cellule 'stake'
    });

  return (
    <div className="w-full p-8 space-8">
      <div className="pb-16 space-y-8">
        <IllustratedExplanationMessage
          title={t('iro.summary.alertConsultation.title')}
          description={t('iro.summary.alertConsultation.content')}
          illustration={
            <img className="w-20" src={messageIllustration} alt="" />
          }
        >
          <div className="flex">
            <Link className="button primary" to={AppRoutes.Survey}>
              <MessageIcon className="w-5 h-5" />
              {t('iro.summary.alertConsultation.button.label')}
            </Link>
          </div>
        </IllustratedExplanationMessage>
        <div className="mt-5">
          <div className="flex justify-between items-center self-stretch">
            <div className="iro_par_enjeux text-[#282a2c] font-['Bitter'] text-xl font-extrabold leading-[150%]">
              {t('iro.summary.title')}
            </div>
          </div>
          <div className="flex items-center justify-between">
            <IroSummaryFilters
              {...{
                currentPillarId,
                setCurrentPillarId,
              }}
            />
            <MaterialityThresholdButton companyId={referential.company.id} />
          </div>
        </div>
        {referential.pillars && (
          <Table<IroSummaryStakeRow>
            {...{
              tableClassName: 'table table-fixed',
              columns,
              data,
              enableSorting: true,
              enableExpanding: true,
              initialState: {
                sorting: [
                  {
                    id: 'maxMateriality',
                    desc: true,
                  },
                ],
              },
              getSortedRowModel: getSortedRowModel(),
              expandedRowRenderFn: (data, columnLength) => (
                <SummaryDetailIroRow data={data} columnLength={columnLength} />
              ),
              getCellClassName,
            }}
          />
        )}
      </div>
    </div>
  );
};
