import React from 'react';
import gapAnalysis from '../../../assets/images/illustrations/gapAnalysis/GapAnalysis.svg';
import { useTranslation } from '@hooks/useTranslation';

export const GapAnalysisHero = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { t } = useTranslation();
  return (
    <div className="bg-blue-50">
      <div className="flex px-16 pt-16">
        <div className="flex flex-col w-1/2">
          <div className="text-2xl font-bold">
            {t('gap_analysis.hero.title')}
          </div>
          <div className="">{t('gap_analysis.hero.description')}</div>
        </div>
        <div className="flex-grow">
          <img className="m-auto" src={gapAnalysis} alt="Gap Analysis" />
        </div>
      </div>
      {children}
    </div>
  );
};
