import {
  StandardEnum,
  useAddReportToCompanyMutation,
} from '../../../../graphql/generated';
import { AvailableStandards_StandardFragment } from '../../../../graphql/cms/generated';
import { useToast } from '../../../layout/Toast';
import { useTranslation } from '@hooks/useTranslation';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';

export const AddCompanyReportFormInput = ({
  companyId,
  standard,
  isActivated,
}: {
  companyId: string;
  standard: AvailableStandards_StandardFragment;
  isActivated: boolean;
}) => {
  const projectContext = useProjectContext();
  const [addCompanyReport] = useAddReportToCompanyMutation();
  const toast = useToast();
  const { t } = useTranslation();

  return (
    <>
      <input
        type="checkbox"
        checked={isActivated}
        className="form-checkbox"
        disabled={isActivated}
        onChange={() => {
          addCompanyReport({
            variables: {
              input: {
                report: {
                  company: { id: companyId },
                  standard: standard.slug as StandardEnum,
                },
              },
            },
            onCompleted: () => {
              toast.openToastWithMessage(
                t('toast:company.settings.reports.add.success'),
              );
              projectContext?.refreshCompany();
            },
            onError: (e) => {
              toast.openToastWithError(
                t('toast:company.settings.reports.add.error'),
              );
              console.error(e);
            },
          });
        }}
      />
      <label className="form-input-label">{standard.name}</label>
    </>
  );
};
