import React from 'react';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import { useSimpleMaterialitiesFinancialMatrixQuery } from '../../../../graphql/generated';
import { LoaderFullscreen } from '../../../layout/Loader';
import {
  StakesMaterialityChart,
  TStakeMateriality,
} from '../../survey/questionsDataviz/stakesMaterialityChart/StakesMaterialityChart';
import { useTranslation } from '@hooks/useTranslation';
import { useMatrix } from './MatrixContext';

export function FinancialMaterialityMatrix() {
  const projectContext = useProjectContext();
  const { t } = useTranslation();
  const { stakeholderSegments } = useMatrix();

  // Compute materiality on 2 axis
  const computeMaterialityQuery = useSimpleMaterialitiesFinancialMatrixQuery({
    variables: {
      input: {
        companyId: projectContext?.company?.id || '',
        filters: {
          stakeholderSegments: stakeholderSegments ?? [],
        },
      },
    },
    skip: !projectContext?.company?.id,
    fetchPolicy: 'no-cache',
  });

  if (computeMaterialityQuery.loading) {
    return <LoaderFullscreen />;
  }

  const stakes =
    computeMaterialityQuery?.data?.computeSimpleMaterialitiesFinancial || [];

  const stakesMaterialities: TStakeMateriality[] = stakes.map((stake) => {
    const xMateriality = stake.materialities?.x?.value || 0;
    const yMateriality = stake.materialities?.y?.value || 0;
    return {
      stake,
      xMateriality,
      yMateriality,
      materialityScore: (xMateriality * yMateriality) / 4,
    };
  });

  return (
    <StakesMaterialityChart
      stakesMaterialities={stakesMaterialities}
      options={{
        enableZoom: true,
        enableConsensusLine: true,
        defaultConsensusLineState: false,
        enableStakeholderSegmentsFilter: true,
        xAxisLabel: t(
          'charts.financial_materiality_matrix.importance_axis_label',
        ),
        yAxisLabel: t('charts.financial_materiality_matrix.likelihood_label'),
      }}
    />
  );
}
