import React from 'react';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import { CompanySettings } from '../CompanySettings/CompanySettings';

export const ProjectSettingsContent = () => {
  const projectContext = useProjectContext();

  return (
    projectContext?.company && (
      <div className="w-full py-8 px-16 max-w-5xl">
        <CompanySettings companyId={projectContext?.company.id} />
      </div>
    )
  );
};
