import {
  CompanyLogoInput_CompanyFragment,
  useCompanyLogoInput_CompanyUpdateMutation,
} from '../../../graphql/generated';
import React, { useState } from 'react';
import { CompanyLogo } from './CompanyLogo';
import { MessageBox, MessageBoxType } from '../../layout/MessageBox';
import { useToast } from '../../layout/Toast';
import { useProjectContext } from '../../../providers/ProjectContextProvider';
import { convertFileToBase64 } from '../../../services/UtilsService';
import { DeleteIcon } from '../../icons';
import { useTranslation } from '@hooks/useTranslation';

export function CompanyLogoInput({
  company,
}: {
  company: CompanyLogoInput_CompanyFragment;
}) {
  const { t } = useTranslation();
  const toast = useToast();
  const projectContext = useProjectContext();

  const [logo, setLogo] = useState<string | null | undefined>();

  const [updateCompanyMutation] = useCompanyLogoInput_CompanyUpdateMutation();
  const saveLogo = (base64Logo: string | null) => {
    updateCompanyMutation({
      variables: {
        input: {
          id: company.id,
          logo: base64Logo,
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage(t('toast:company.logo.updated.success'));
        projectContext?.refreshCompany();
      })
      .catch((err) => {
        toast.openToastWithError(t('toast:company.logo.updated.error'));
      });
  };

  const uploadLogo = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target?.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
        toast.openToastWithError(t('toast:company.logo.formatNotSupported'));
        return;
      }
      if (file.size > 1000000) {
        toast.openToastWithError(t('toast:company.logo.fileTooBig'));
        return;
      }
      convertFileToBase64(file)
        .then((base64) => {
          setLogo(base64 as string);
          saveLogo(base64 as string);
        })
        .catch((error) => {
          console.error(error);
          setLogo(null);
          saveLogo(null);
        });
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <label htmlFor="logo" className="form-input-label">
        Logo
      </label>
      <div className="w-60 h-60 border border-gray-100 rounded-xl flex items-center justify-center p-2">
        <CompanyLogo companyId={company.id} size={'big'} />
      </div>
      <div className="flex gap-2 bg-gray-50 rounded-xl p-4 w-96 justify-between items-center">
        <input
          type="file"
          id="logo"
          name="logo"
          accept="image/jpeg, image/png"
          onChange={(event) => uploadLogo(event)}
        />
        {logo && (
          <button
            className="unstyled small text-gray-500"
            onClick={() => {
              setLogo(null);
              saveLogo(null);
            }}
          >
            <DeleteIcon />
          </button>
        )}
      </div>
      <div className="w-96">
        <MessageBox type={MessageBoxType.Info}>
          {t('company.settings.logo.infoTips')}
        </MessageBox>
      </div>
    </div>
  );
}
