import React from 'react';
import { useProjectContext } from '../../../providers/ProjectContextProvider';
import { PageTitle } from '../../../components/nav/PageTitle';
import { Breadcrumb } from '../../../components/nav/Breadcrumb';
import { LoaderFullscreen } from '../../../components/layout/Loader';
import { HomeScreen } from '../home/HomeScreen';
import { ImportXlsFile } from '../../../components/project/maturity/ImportXlsFile';

export function MaturityEvaluationImportScreen() {
  const projectContext = useProjectContext();

  if (projectContext?.isLoadingCompany) {
    return <LoaderFullscreen />;
  }

  if (!projectContext?.company) {
    return <HomeScreen />;
  }

  return (
    <>
      <PageTitle title="Import d'une évaluation de maturité" />
      <Breadcrumb />
      <div className="main-content w-full py-8 space-y-8">
        <ImportXlsFile />
      </div>
    </>
  );
}
