import React from 'react';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import { LoaderFullscreen } from '../../../layout/Loader';
import { StakesMaterialityChart } from '../../survey/questionsDataviz/stakesMaterialityChart/StakesMaterialityChart';
import { useTranslation } from '@hooks/useTranslation';
import {
  DoubleMaterialityStaticMatrix_ReferentialFragment,
  useDoubleMaterialityStaticMatrixQuery,
} from '../../../../graphql/generated';
import { TMaterialityMatrixChartJsOptions } from '../../../generic/chart/materialityBubbleChart/types';

type DoubleMaterialityProps = {
  financialMaterialityThreshold?: number | null;
  impactMaterialityThreshold?: number | null;
  referential?: DoubleMaterialityStaticMatrix_ReferentialFragment | null;
};

export const DoubleMaterialityStaticMatrix = ({
  financialMaterialityThreshold,
  impactMaterialityThreshold,
}: DoubleMaterialityProps) => {
  const projectContext = useProjectContext();
  const { t } = useTranslation();

  const query = useDoubleMaterialityStaticMatrixQuery({
    variables: {
      referentialId: projectContext?.company?.referential?.id || '',
      companyId: projectContext?.company?.id || '',
    },
    fetchPolicy: 'no-cache',
  });

  const referential = query.data?.referential ?? null;
  const stakeMaterialities = query.data?.computeDoubleMaterialities ?? null;

  const stakesMaterialities = stakeMaterialities?.map((stake) => {
    return {
      stake,
      xMateriality: stake.financialMaterialityScore ?? 0,
      yMateriality: stake.impactMaterialityScore ?? 0,
      materialityScore: Math.max(
        stake.financialMaterialityScore ?? 0,
        stake.impactMaterialityScore ?? 0,
      ),
    };
  });

  if (query.loading) {
    return <LoaderFullscreen />;
  }

  const chartOptions: TMaterialityMatrixChartJsOptions = {
    enableConsensusLine: false,
    enableZoom: true,
    enableThresholds: true,
    xAxisLabel: t(
      'charts.double_materiality_matrix.financial_materiality_axis_label',
    ),
    yAxisLabel: t(
      'charts.double_materiality_matrix.impact_materiality_axis_label',
    ),
  };

  if (financialMaterialityThreshold) {
    chartOptions.xThreshold = financialMaterialityThreshold;
  } else if (referential?.company.financialMaterialityThreshold !== null) {
    chartOptions.xThreshold =
      referential?.company.financialMaterialityThreshold;
  }

  if (impactMaterialityThreshold) {
    chartOptions.yThreshold = impactMaterialityThreshold;
  } else if (referential?.company.impactMaterialityThreshold !== null) {
    chartOptions.yThreshold = referential?.company.impactMaterialityThreshold;
  }

  return (
    <StakesMaterialityChart
      stakesMaterialities={stakesMaterialities || []}
      options={chartOptions}
    />
  );
};
