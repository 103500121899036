import {
  CurrentUserDocument,
  ProCompanyMaxProjectsCountProgressBarDocument,
  ProjectContextualMenu_CompanyFragment,
  useArchiveProjectMutation,
} from '../../../../graphql/generated';
import { useToast } from '../../../layout/Toast';
import { useModal } from '../../../layout/Modal';
import { useCurrentUser } from '../../../../providers/CurrentUserProvider';
import { useTranslation } from '@hooks/useTranslation';
import { ConfirmationModal } from '../../../modals/ConfirmationModal';
import { Loader } from '../../../generic/Loader';
import { GroupIcon } from '../../../icons';
import React from 'react';

export function ArchiveCompanyButton({
  company,
}: {
  company: ProjectContextualMenu_CompanyFragment;
}) {
  const toast = useToast();
  const modal = useModal();
  const currentUser = useCurrentUser();
  const { t } = useTranslation();
  const [archiveProjectMutation, { loading }] = useArchiveProjectMutation();

  const saveCompany = () => {
    archiveProjectMutation({
      refetchQueries: [
        { query: CurrentUserDocument },
        {
          query: ProCompanyMaxProjectsCountProgressBarDocument,
          variables: { companyId: currentUser?.company?.id },
        },
      ],
      variables: {
        id: company.id,
      },
    })
      .then(() => {
        toast.openToastWithMessage(t('toast:project.archive.success'));
      })
      .catch((err) => {
        toast.openToastWithError(t('toast:project.archive.error'));
        throw err;
      });
  };

  return (
    <button
      className="contextual-menu-item"
      disabled={loading}
      onClick={() =>
        modal.openModalWithComponent(
          <ConfirmationModal
            message={t('project.contextualMenu.archiveProject.confirmMessage', {
              companyName: company.name,
            })}
            callbackIfConfirmed={saveCompany}
          />,
          t('project.contextualMenu.archiveProject.confirmTitle', {
            companyName: company.name,
          }),
        )
      }
    >
      {loading ? <Loader /> : <GroupIcon />}
      <div>{t('project.contextualMenu.archiveProject.button')}</div>
    </button>
  );
}
