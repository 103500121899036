import {
  CompanyType,
  useJoinCompanyProjectListQuery,
} from '../../../../graphql/generated';
import { useCurrentUser } from '../../../../providers/CurrentUserProvider';
import { LoaderFullscreen } from '../../../layout/Loader';
import React from 'react';
import { CompaniesList } from './CompaniesList';

export function ArchivedProjectList() {
  const currentUser = useCurrentUser();

  const companyProjectsQuery = useJoinCompanyProjectListQuery({
    variables: { companyId: currentUser?.company?.id || '' },
    skip: !currentUser?.company?.id,
    fetchPolicy: 'network-only',
  });

  if (companyProjectsQuery.loading) {
    return <LoaderFullscreen />;
  }

  const archivedProjects =
    currentUser?.contributions
      ?.map((contribution) => contribution.contributesTo)
      .filter((company) => company.archivedDate)
      .filter((company) => company.type === CompanyType.EndUser) || [];

  return (
    <div className="max-w-6xl mt-16 pb-16">
      {archivedProjects && archivedProjects.length > 0 ? (
        <CompaniesList companies={archivedProjects} />
      ) : null}
    </div>
  );
}
