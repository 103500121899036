import React from 'react';
import { useProjectContext } from '../../../providers/ProjectContextProvider';
import { PageTitle } from '../../../components/nav/PageTitle';
import { Referential } from '../../../components/project/stakes/Referential';
import { Breadcrumb } from '../../../components/nav/Breadcrumb';
import { withProjectContext } from '../../../providers/withProjectContext';
import { LoaderFullscreen } from '../../../components/layout/Loader';
import {
  MessageBox,
  MessageBoxType,
} from '../../../components/layout/MessageBox';
import { StakesHeader } from './StakesHeader';

export const ReferentialScreen = withProjectContext(
  () => {
    const projectContext = useProjectContext();

    return (
      <>
        <PageTitle title="Enjeux" />
        <Breadcrumb />
        <StakesHeader />
        <div className="p-8 w-full">
          {(projectContext?.isLoadingCompany && <LoaderFullscreen />) ||
            (projectContext?.company?.referential && (
              <Referential
                referentialId={projectContext.company.referential?.id}
              />
            )) || (
              <MessageBox type={MessageBoxType.Error}>
                referential not found
              </MessageBox>
            )}
        </div>
      </>
    );
  },
  { requiredCompany: true },
);

