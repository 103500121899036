import React from 'react';
import clsx from 'clsx';
import surveyCollaborators from '../../assets/images/illustrations/survey/survey-collaborators.png';
import { useRightDrawer } from '../layout/RightDrawer';
import {
  SurveyEmailDrawer_SurveyFragment,
  SurveyTarget,
} from '../../graphql/generated';
import { getPublicLinkToSurvey } from '../../services/SurveyService';
import { useProjectContext } from '../../providers/ProjectContextProvider';

export function SurveyEmailDrawer({
  survey,
}: {
  survey: SurveyEmailDrawer_SurveyFragment;
}) {
  const projectContext = useProjectContext();
  const companyName = projectContext?.company?.name || 'Votre entreprise';
  const { closeDrawer } = useRightDrawer();

  return (
    <>
      <div className="p-6 w-full order-last">
        <button className="w-full" onClick={closeDrawer}>
          Fermer l&apos;aperçu
        </button>
      </div>
      <div className="overflow-y-scroll">
        <div className="flex justify-between items-center bg-purple-50 p-8">
          <div className={clsx('flex flex-col gap-2')}>
            <p className={clsx('text-sm')}>Aperçu de l&apos;email</p>
            {survey.target === SurveyTarget.Collaborators && (
              <h3 className="">Interne</h3>
            )}
            {survey.target === SurveyTarget.External && (
              <h3 className="">Externe</h3>
            )}
          </div>
          <img src={surveyCollaborators} alt="" className="w-20 h-20" />
        </div>
        <div className="p-8">
          <h3 className="title-h6 flex gap-2 items-center mb-4">
            <div>Modèle d&apos;email à envoyer</div>
          </h3>

          <div className="p-4 bg-gray-50 rounded-lg text-base">
            {survey.target === SurveyTarget.Collaborators && (
              <CollaboratorsEmailBody
                survey={survey}
                companyName={companyName}
              />
            )}
            {survey.target === SurveyTarget.External && (
              <ExternalEmailBody survey={survey} companyName={companyName} />
            )}
            {survey.target === SurveyTarget.Ceo && (
              <CeoEmailBody survey={survey} companyName={companyName} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

function ExternalEmailBody({
  survey,
  companyName,
}: {
  survey: SurveyEmailDrawer_SurveyFragment;
  companyName: string;
}) {
  return (
    <>
      <p className="mb-4 font-bold">Objet : consultation pour {companyName}</p>
      <p className="mb-4">Bonjour,</p>
      <p>
        {companyName} lance une enquête RSE (Responsabilité Sociétale des
        Entreprises) auprès de ses partenaires et parties prenantes clés, dont
        vous faites partie. {companyName} souhaite en effet à davantage intégrer
        son impact social et environnemental à la stratégie, la culture et au
        pilotage global de l&apos;entreprise.
      </p>
      <p className="mb-4">
        Dans cette perspective, votre avis compte beaucoup.
      </p>
      <p className="mb-4">
        L&apos;objectif : recueillir votre perception des enjeux
        environnementaux et sociaux importants pour {companyName} afin de
        devenir une entreprise responsable inspirante, bénéfique pour son
        écosystème et la société en général.
      </p>
      <p className="mb-4">
        Nous vous soumettons donc le présent questionnaire en ligne :
      </p>
      <p className="mb-4">
        <a className="underline" href={getPublicLinkToSurvey(survey.id)}>
          {getPublicLinkToSurvey(survey.id)}
        </a>
      </p>
      <p className="mb-4">
        Les résultats permettront à {companyName} d&apos;identifier les actions
        à mener de façon prioritaire et ainsi, d&apos;entamer la transformation
        nécessaire pour s&apos;adapter au monde qui vient.
      </p>
      <p className="mb-4">
        Merci d&apos;avance pour le temps que vous y accorderez !
      </p>
      <p className="mb-4">Bonne journée</p>
    </>
  );
}

function CollaboratorsEmailBody({
  survey,
  companyName,
}: {
  survey: SurveyEmailDrawer_SurveyFragment;
  companyName: string;
}) {
  return (
    <>
      <p className="mb-4 font-bold">
        Objet : consultation pour de {companyName}
      </p>
      <p className="mb-4">Bonjour à toutes et à tous,</p>
      <p className="mb-4">
        {companyName} a débuté un diagnostic RSE (Responsabilité Sociétale des
        Entreprises), afin d&apos;intégrer davantage son impact social et
        environnemental à la stratégie, la culture et au pilotage global de
        l&apos;entreprise. Dans cette perspective, votre avis compte beaucoup.
      </p>
      <p className="mb-4">
        Nous souhaitons connaître les enjeux environnementaux et sociaux que
        vous estimez importants, et sur lesquels vous pensez que {companyName}{' '}
        doit s&apos;engager au quotidien et pour l&apos;avenir.
      </p>
      <p className="mb-4">
        Ainsi, nous vous soumettons ce questionnaire en ligne :
      </p>
      <p className="mb-4">
        <a
          className="underline font-semibold"
          href={getPublicLinkToSurvey(survey.id)}
        >
          {getPublicLinkToSurvey(survey.id)}
        </a>
      </p>
      <p className="mb-4">
        Les résultats permettront à {companyName} d&apos;identifier les actions
        à mener de façon prioritaire et ainsi, d&apos;entamer la transformation
        nécessaire pour s&apos;adapter au monde qui vient.
      </p>
      <p className="mb-4">
        Merci d&apos;avance pour le temps que vous y accorderez !
      </p>
      <p className="mb-4">Bonne journée</p>
    </>
  );
}

function CeoEmailBody({
  survey,
  companyName,
}: {
  survey: SurveyEmailDrawer_SurveyFragment;
  companyName: string;
}) {
  return (
    <>
      <p className="mb-4 font-bold">
        Objet : consultation pour de {companyName}
      </p>
      <p className="mb-4">Bonjour,</p>
      <p className="mb-4">
        {companyName} a débuté un diagnostic RSE (Responsabilité Sociétale des
        Entreprises), afin d&apos;intégrer davantage son impact social et
        environnemental à la stratégie, la culture et au pilotage global de
        l&apos;entreprise. Dans cette perspective, votre avis compte beaucoup.
      </p>
      <p className="mb-4">
        Nous souhaitons connaître les enjeux environnementaux et sociaux que
        vous estimez importants, et sur lesquels vous pensez que {companyName}{' '}
        doit s&apos;engager au quotidien et pour l&apos;avenir. En tant que
        membre de sa direction, votre avis compte beaucoup !
      </p>
      <p className="mb-4">
        Ainsi, nous vous soumettons ce questionnaire en ligne :
      </p>
      <p className="mb-4">
        <a href={getPublicLinkToSurvey(survey.id)}>
          {getPublicLinkToSurvey(survey.id)}
        </a>
      </p>
      <p className="mb-4">
        Les résultats permettront à {companyName} d&apos;identifier les actions
        à mener de façon prioritaire et ainsi, d&apos;entamer la transformation
        nécessaire pour s&apos;adapter au monde qui vient.
      </p>
      <p className="mb-4">
        Merci d&apos;avance pour le temps que vous y accorderez !
      </p>
      <p className="mb-4">Bonne journée</p>
    </>
  );
}
