import {
  ActionFieldsFragment,
  ActionPriorityLevel,
} from '../../../graphql/generated';
import { PriorityBadge } from '../../badges/PriorityBadge';
import React, { useState } from 'react';
import { useProjectContext } from '../../../providers/ProjectContextProvider';
import { Link } from 'react-router-dom';
import { ThemeIcon } from '../../badges/ThemeIcon';
import { ComplexityBadge } from '../../badges/ComplexityBadge';
import { PriceBadge } from '../../badges/PriceBadge';
import { ActionIllustrationIcon } from './ActionIllustrationIcon';
import { getActionLink } from '../../../screens/ScreensRouter';
import { ChevronRightIcon } from '../../icons';

export function ActionList({ actions }: { actions: ActionFieldsFragment[] }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const isExpandable = actions.length > 5;
  const sortedActions = actions.toSorted(
    (a, b) =>
      mapActionPriorityLevelToNumber(a.priorityLevel) -
      mapActionPriorityLevelToNumber(b.priorityLevel),
  );
  const displayedActions = isExpanded
    ? sortedActions
    : sortedActions.slice(0, 5);
  return (
    <div>
      <div className="flex gap-4 text-gray-500 text-sm">
        <div className="flex items-center gap-2 mb-2 w-2/3">
          <div>{sortedActions.length} Actions</div>
        </div>
        <div className="w-1/3 grid grid-cols-5 gap-2 mb-2 justify-items-center items-end mr-2">
          <div>Pilier</div>
          <div>Priorité</div>
          <div>Complexité</div>
          <div>Coût</div>
        </div>
      </div>
      <div className="relative">
        {displayedActions.map((action) => (
          <div
            className="mb-2 border-2 border-white rounded-lg hover:border-gray-900"
            key={action.id}
          >
            <ActionRow action={action} />
          </div>
        ))}
        {isExpandable && !isExpanded && (
          <div className="absolute h-28 bottom-0 w-full bg-gradient-from-b from-white bg-gradient-to-t to-transparent"></div>
        )}
      </div>
      {isExpandable && (
        <div
          className="flex items-center gap-2 cursor-pointer text-sm text-gray-500"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? <div>Réduire</div> : <div>Voir toutes les actions</div>}
          <ChevronRightIcon
            className={isExpanded ? '-rotate-90' : 'rotate-90'}
          />
        </div>
      )}
    </div>
  );
}

function ActionRow({ action }: { action: ActionFieldsFragment }) {
  const projectContext = useProjectContext();

  return (
    <Link to={getActionLink(projectContext?.company !== undefined, action.id)}>
      <div className="rounded-xl flex items-center justify-between gap-2">
        <div className="font-bold text-gray-900 w-2/3 flex items-center gap-4">
          <ActionIllustrationIcon action={action} />
          <div>{action.title}</div>
        </div>
        <div className="w-1/3 grid grid-cols-5 gap-2 justify-items-center items-center">
          <ThemeIcon theme={action.theme} enableTooltip={true} />
          <PriorityBadge priorityLevel={action.priorityLevel} />
          <ComplexityBadge complexityLevel={action.complexityLevel} />
          <PriceBadge priceLevel={action.priceLevel} />
          <div>
            <button className="tertiary">
              <ChevronRightIcon className="text-gray-900 w-4 h-4" />
            </button>
          </div>
        </div>
      </div>
    </Link>
  );
}

export function mapActionPriorityLevelToNumber(
  priorityLevel: ActionPriorityLevel,
): number {
  switch (priorityLevel) {
    case ActionPriorityLevel.Unimportant:
      return 0;
    case ActionPriorityLevel.Important:
      return 1;
    case ActionPriorityLevel.Priority:
      return 2;
    case ActionPriorityLevel.Urgent:
      return 3;
  }
}
