import React from 'react';
import { useTranslation } from '@hooks/useTranslation';
import { useGapAnalysisSynthesis_RequirementsQuery } from '../../../../graphql/cms/generated';
import { cmsClient } from '../../../../graphql/clients/cmsClient';
import { getNumberOfDatapoints } from '../summary/useGapAnalysisTopicDRTableColumns';
import { LoaderFullscreen } from '../../../layout/Loader';
import {
  GapAnalysisSynthesisFragment,
  StandardEnum,
  useGapAnalysisSynthesisQuery,
} from '../../../../graphql/generated';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import { MessageBox, MessageBoxType } from '../../../layout/MessageBox';
import { DoughnutChart } from '../../../generic/chart/DoughnutChart';

export function GapAnalysisSynthesis() {
  const context = useProjectContext();

  const apiQuery = useGapAnalysisSynthesisQuery({
    variables: {
      input: {
        company: { id: context?.company?.id! },
        standard: StandardEnum.Csrd,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  if (apiQuery.loading) {
    return <LoaderFullscreen />;
  }

  if (apiQuery.error) {
    return (
      <MessageBox type={MessageBoxType.Error}>
        {apiQuery.error.message}
      </MessageBox>
    );
  }

  if (apiQuery.data?.companyReport) {
    return <GapAnalysisSynthesisCharts report={apiQuery.data.companyReport} />;
  }
}

function GapAnalysisSynthesisCharts({
  report,
}: {
  report: GapAnalysisSynthesisFragment;
}) {
  const { t, i18n } = useTranslation();

  // Get all DR slugs to query CMS
  const disclosureRequirementSlugs = report.topics.flatMap((topic) =>
    topic.disclosureRequirements.map((dr) => dr.referenceSlug),
  );

  const requirementsQuery = useGapAnalysisSynthesis_RequirementsQuery({
    client: cmsClient,
    variables: {
      locale: i18n.language,
      slugs: disclosureRequirementSlugs,
    },
  });
  const requirements = requirementsQuery.data?.disclosureRequirements || [];

  const datapointsCount = requirements.reduce((acc, dr) => {
    return dr ? acc + getNumberOfDatapoints(dr) : acc;
  }, 0);

  return (
    <div className="px-16 py-8 grid grid-cols-1 xl:grid-cols-2 gap-4">
      <div className="bg-gray-50 rounded-xl p-8 items-center gap-8 space-y-4">
        <h6 className="text-center">
          {t('gap_analysis.synthesis.selectedDatapointsOverTotalChart.title')}
        </h6>
        <DoughnutChart
          data={[
            report.gapAnalysisProgress?.selectedDatapoints || 0,
            datapointsCount,
          ]}
          labels={[
            t(
              'gap_analysis.synthesis.selectedDatapointsOverTotalChart.selectedDatapoints',
            ),
            t(
              'gap_analysis.synthesis.selectedDatapointsOverTotalChart.totalDatapoints',
            ),
          ]}
          title={t(
            'gap_analysis.synthesis.selectedDatapointsOverTotalChart.title',
          )}
        />
      </div>
      <div className="bg-gray-50 rounded-xl p-8 items-center gap-8 space-y-4">
        <h6 className="text-center">
          {t(
            'gap_analysis.synthesis.availableDatapointsOverSelectedChart.title',
          )}
        </h6>
        <DoughnutChart
          data={[
            report.gapAnalysisProgress?.availableDatapoints || 0,
            report.gapAnalysisProgress?.selectedDatapoints || 0,
          ]}
          labels={[
            t(
              'gap_analysis.synthesis.availableDatapointsOverSelectedChart.availableDatapoints',
            ),
            t(
              'gap_analysis.synthesis.availableDatapointsOverSelectedChart.selectedDatapoints',
            ),
          ]}
          title={t(
            'gap_analysis.synthesis.availableDatapointsOverSelectedChart.title',
          )}
        />
      </div>
    </div>
  );
}
