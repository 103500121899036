import { useParams } from 'react-router-dom';
import React from 'react';
import { PageTitle } from '../../../components/nav/PageTitle';
import { useProjectContext } from '../../../providers/ProjectContextProvider';
import {
  MaturityEvaluationScreen_MaturityEvaluationFragment,
  useCompanyAbstractQuery,
  useMaturityEvaluationScreenQuery,
} from '../../../graphql/generated';
import { LoaderFullscreen } from '../../../components/layout/Loader';
import {
  MessageBox,
  MessageBoxType,
} from '../../../components/layout/MessageBox';
import { MaturityEvaluation } from '../../../components/project/maturity/MaturityEvaluation';

export function MaturityEvaluationScreen() {
  const { surveyId } = useParams();

  const { data, loading } = useMaturityEvaluationScreenQuery({
    variables: {
      maturityEvaluationId: surveyId || '',
    },
    skip: !surveyId,
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <LoaderFullscreen />;
  }

  if (data?.maturityEvaluation) {
    return (
      <MaturityEvaluationScreenInner
        maturityEvaluation={data.maturityEvaluation}
      />
    );
  } else {
    return (
      <MessageBox type={MessageBoxType.Error}>
        <p>
          Une erreur est survenue lors de la récupération de l&apos;évaluation
          de maturité.
        </p>
      </MessageBox>
    );
  }
}

function MaturityEvaluationScreenInner({
  maturityEvaluation,
}: {
  maturityEvaluation: MaturityEvaluationScreen_MaturityEvaluationFragment;
}) {
  // Select and load automatically the corresponding company
  const projectContext = useProjectContext();
  const { data } = useCompanyAbstractQuery({
    variables: {
      id: maturityEvaluation.company?.id || '',
    },
    skip: !maturityEvaluation.company,
    fetchPolicy: 'network-only',
  });
  if (projectContext && !projectContext?.company && data?.company) {
    projectContext.updateCompany(data?.company.id, false);
  }

  return (
    <>
      <PageTitle title="Évaluation de maturité" />
      <MaturityEvaluation survey={maturityEvaluation} />
    </>
  );
}
