import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import React from 'react';
import { CompanyUpdateInfo } from '../CompanyUpdateInfo';
import { CompanyType } from '../../../../graphql/generated';
import { TemplateCompany } from '../TemplateCompany';

export const ProjectCompanyContent = () => {
  const projectContext = useProjectContext();
  if (!projectContext?.company) {
    return null;
  }
  const companyForm =
    projectContext?.company?.type === CompanyType.Template ? (
      <TemplateCompany company={projectContext.company} />
    ) : (
      <CompanyUpdateInfo companyId={projectContext.company.id} />
    );

  return (
    projectContext?.company && (
      <div className="w-full py-8 px-16 max-w-5xl">{companyForm}</div>
    )
  );
};
