import { FormEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFirebaseAuth } from '../../providers/FirebaseProvider';
import { signInWithEmailAndPassword } from 'firebase/auth';

import logo from '../../assets/images/logo-with-name.svg';
import { PageTitle } from '../../components/nav/PageTitle';
import { useCurrentAuth } from '../../providers/CurrentAuthProvider';
import { useCurrentUser } from '../../providers/CurrentUserProvider';
import { Loader } from '../../components/generic/Loader';
import { MessageBox, MessageBoxType } from '../../components/layout/MessageBox';
import {
  AuthRoles,
  useCompanyNameQuery,
  useRegisterAccountMutation,
} from '../../graphql/generated';
import PasswordInput from '../../components/generic/form/PasswordInput';
import { LoaderFullscreen } from '../../components/layout/Loader';
import { ErrorIcon } from '../../components/icons';

import { AppRoutes } from '../AppRoutes';

export function RegisterScreen() {
  const navigate = useNavigate();
  const firebaseAuth = useFirebaseAuth();
  const currentAuth = useCurrentAuth();
  const currentUser = useCurrentUser();

  const { companyId } = useParams();
  const { role } = useParams();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [error, setError] = useState<string | null>(null);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [displayConfirmInvitationButton, setDisplayConfirmInvitationButton] =
    useState(false);

  const [registerAccount] = useRegisterAccountMutation();

  useEffect(() => {
    if (isFormSubmitted) {
      if (currentAuth === undefined || currentUser === undefined) {
        setError(null);
        setIsLoading(true);
      } else if (currentAuth && currentUser === null) {
        setError(
          "Votre compte n'est pas initialisé. Veuillez nous contacter pour finaliser votre inscription",
        );
        setIsLoading(false);
      } else {
        setError('Mauvais mot de passe ou compte inexistant');
        setIsLoading(false);
      }

      // Redirect logged in user to /pro or / depending on his role
      if (currentAuth && currentUser) {
        const roles = currentUser.auth?.roles;
        navigate('/', {
          replace: true,
        });
      }
    } else {
      // Form not submitted, but user logged in -> we display a lighter invitation form
      if (currentAuth && currentUser) {
        setDisplayConfirmInvitationButton(true);
      }
    }
  }, [isFormSubmitted, currentAuth, navigate, currentUser]);

  const { data, loading } = useCompanyNameQuery({
    variables: {
      id: companyId || '',
    },
    skip: !companyId,
  });

  // Check if invited by a company
  if (!companyId) {
    return (
      <MessageBox type={MessageBoxType.Error}>
        Vous devez être invité par une entreprise pour accéder à cette page
      </MessageBox>
    );
  }

  // Loader
  if (loading) {
    return (
      <div className="w-full h-screen">
        <LoaderFullscreen />
      </div>
    );
  }

  // Check if company exist
  if (!data?.companyName?.id) {
    return (
      <MessageBox type={MessageBoxType.Error}>
        Le lien d'invitation n'est pas valide
      </MessageBox>
    );
  }

  const authRole = mapRoleParamToAuthRole(role);

  const confirmJoin = () => {
    registerAccount({
      variables: {
        input: {
          companyId: companyId || '',
          email: currentAuth?.email || '',
          password,
          role: authRole,
          firstName,
          lastName,
        },
      },
    })
      .then(() => {
        navigate(AppRoutes.Home, { replace: true });
        // TODO: avoid a force reload. We should refresh the CompanyList (contributors instead)
        window.location.reload();
      })
      .catch((err) => {
        console.error(err);
        setIsFormSubmitted(true);
        setIsLoading(false);
        setError('Une erreur est survenue lors de la création de votre compte');
      });
  };
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    setError(null);

    registerAccount({
      variables: {
        input: {
          companyId: companyId || '',
          email,
          password,
          role: authRole,
          firstName,
          lastName,
        },
      },
    })
      .then((res) => {
        if (res.data?.registerAccount.authId) {
          signInWithEmailAndPassword(firebaseAuth, email, password)
            .catch((err) => {
              console.error(err);
              setIsFormSubmitted(true);
              setIsLoading(false);
              setError('Mauvais mot de passe ou compte inexistant');
            })
            .then(() => {
              setIsFormSubmitted(true);
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
      })
      .catch((err) => {
        console.error(err);
        setIsFormSubmitted(true);
        setIsLoading(false);
        setError('Une erreur est survenue lors de la création de votre compte');
      });
  };

  const onPasswordChange = (value: string) => {
    setPassword(value);
    setError(null);
  };

  return (
    <>
      <PageTitle title={`Rejoindre ${data?.companyName.name} sur Good Steps`} />

      <div className="w-full h-screen overflow-scroll flex flex-col p-8 items-stretch">
        <div className="flex items-center gap-4">
          <img className="h-12 w-auto" src={logo} alt="Good Steps logo" />
          {data?.companyName?.logo && (
            <div className="flex items-center gap-4">
              <div className="font-title font-extrabold text-gray-500 text-xl">
                &
              </div>
              <div className="shrink-0 h-12 flex items-center justify-center">
                <img
                  src={data?.companyName?.logo}
                  className="shrink-0 h-full rounded-md"
                  alt=""
                />
              </div>
            </div>
          )}
        </div>
        <div className="grow flex gap-8 justify-between items-center self-center max-w-6xl">
          <div className="">
            <h1>Rejoignez {data?.companyName.name} sur Good Steps !</h1>

            {displayConfirmInvitationButton ? (
              <div className="flex flex-col  gap-4">
                <p className="text-gray-500 mt-4">
                  Bonjour ! Vous avez été invité à rejoindre le projet{' '}
                  {data?.companyName.name} sur la plateforme Good Steps.
                </p>
                <button className="primary purple" onClick={confirmJoin}>
                  Rejoindre le projet
                </button>
              </div>
            ) : (
              <>
                {authRole === AuthRoles.Coach && (
                  <p className="text-gray-500 mt-4">
                    Vous avez été invité à rejoindre le projet{' '}
                    {data?.companyName.name} sur la plateforme Good Steps.
                  </p>
                )}

                <div className="mt-8">
                  <form
                    name="loginForm"
                    className="space-y-6"
                    onSubmit={handleSubmit}
                  >
                    <div className="grid grid-cols-2 gap-4">
                      <div className="space-y-1">
                        <label htmlFor="firstName">Prénom</label>
                        <input
                          id="firstName"
                          name="firstName"
                          type="text"
                          value={firstName}
                          onChange={(e) => {
                            setFirstName(e.target.value);
                            setError(null);
                          }}
                          required
                        />
                      </div>

                      <div className="space-y-1">
                        <label htmlFor="lastName">Nom</label>
                        <input
                          id="lastName"
                          name="lastName"
                          type="text"
                          value={lastName}
                          onChange={(e) => {
                            setLastName(e.target.value);
                            setError(null);
                          }}
                          required
                        />
                      </div>
                    </div>

                    <div className="space-y-1">
                      <label htmlFor="email">Votre email</label>
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setError(null);
                        }}
                        required
                      />
                    </div>

                    <div className="space-y-1">
                      <label htmlFor="password">Mot de passe</label>
                      <PasswordInput
                        onChangeCallback={onPasswordChange}
                        value={password}
                      />
                    </div>

                    <div className="flex justify-center">
                      {isLoading ? (
                        <Loader className="w-10 h-10 text-black" />
                      ) : (
                        <input
                          className="primary purple w-full"
                          type="submit"
                          value="Créer votre compte"
                        />
                      )}
                    </div>
                    {error && (
                      <div className="p-4 flex gap-4 items-center bg-red-50 text-red-700">
                        <ErrorIcon className="shrink-0" />
                        {error}
                      </div>
                    )}
                  </form>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

function mapRoleParamToAuthRole(role: string | undefined): AuthRoles | null {
  switch (role) {
    case 'coach':
      return AuthRoles.Coach;
    default:
      return null;
  }
}
