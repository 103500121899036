import { cmsClient } from '../../../../graphql/clients/cmsClient';
import { LoaderFullscreen } from '../../../layout/Loader';
import React, { useEffect } from 'react';
import {
  GapAnalysisDisclosureRequirement_DisclosureRequirementFragment,
  useGapAnalysisDisclosureRequirementBySlugQuery,
} from '../../../../graphql/cms/generated';

import { DisclosureProvider } from './DisclosureContext';
import { DatapointsAccordion } from './datapoint/DatapointsAccordion';
import { DisclosureRequirement } from './content/DisclosureRequirement';
import { MessageBox, MessageBoxType } from '../../../layout/MessageBox';
import { useTranslation } from '@hooks/useTranslation';
import { useNavContext } from '../../../../providers/NavContextProvider';
import { LastUpdate } from '../../../generic/LastUpdate';
import {
  GapAnalysisSummary_DataPointFragment,
  StandardEnum,
  useGapAnalysisDisclosureRequirement_DrBySlugQuery,
} from '../../../../graphql/generated';
import { Guidances } from '../../../cms/disclosureRequirements/Guidances';
import {
  extractDatapointsFromDisclosureRequirement,
  extractGuidelinesFromDisclosureRequirement,
} from '../../../cms/disclosureRequirements/utils';
import { ProgressBar, ProgressBarStyles } from '../../../generic/ProgressBar';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';

export const GapAnalysisDisclosureRequirement = ({
  referenceSlug,
}: {
  referenceSlug: string;
}) => {
  const { t } = useTranslation();
  const projectContext = useProjectContext();
  const csrdReport = projectContext?.company?.reports?.find(
    (report) => report.standard === StandardEnum.Csrd,
  );

  const cmsQuery = useGapAnalysisDisclosureRequirementBySlugQuery({
    client: cmsClient,
    variables: { slug: referenceSlug },
    fetchPolicy: 'cache-and-network',
  });

  const query = useGapAnalysisDisclosureRequirement_DrBySlugQuery({
    variables: { reportId: csrdReport?.id || '', referenceSlug },
    fetchPolicy: 'cache-and-network',
  });
  const disclosureRequirement = query?.data?.reportDisclosureRequirements?.[0];

  const cmsDisclosureRequirement = cmsQuery?.data?.disclosureRequirements?.[0];
  const { setTitle, setActions, setOnClose } = useNavContext();

  useEffect(() => {
    if (cmsDisclosureRequirement && disclosureRequirement) {
      setTitle(cmsDisclosureRequirement.title);
      const dates = [
        ...disclosureRequirement.dataPoints.map((dp) => dp.updatedAt),
      ];
      if (dates.length > 0) {
        const mostRecentUpdateDate = dates.reduce((a, b) => (a > b ? a : b));
        setActions([
          {
            key: 'lastUpdateDate',
            component: <LastUpdate lastUpdated={mostRecentUpdateDate} />,
          },
        ]);
      }
    }
  }, [
    cmsDisclosureRequirement,
    disclosureRequirement,
    setTitle,
    setActions,
    setOnClose,
    t,
  ]);

  const cmsDatapoints = cmsDisclosureRequirement
    ? extractDatapointsFromDisclosureRequirement(cmsDisclosureRequirement)
    : [];
  const guidelines = cmsDisclosureRequirement
    ? extractGuidelinesFromDisclosureRequirement(cmsDisclosureRequirement)
    : [];

  if (cmsQuery.loading) {
    return <LoaderFullscreen />;
  }

  const datapoints = disclosureRequirement?.dataPoints || [];

  return (
    <div className="w-full shadow-inner">
      {cmsDisclosureRequirement ? (
        <DisclosureProvider>
          <div>
            <Header
              cmsDisclosureRequirement={cmsDisclosureRequirement}
              datapoints={datapoints}
            />
            <div className="w-full bg-gray-50 border-t border-gray-100">
              <div className="main-content flex items-stretch gap-8 py-4">
                <div className="w-4/12 space-y-4">
                  <h3>
                    {t(
                      'disclosure_requirements.disclosure_requirement.datapoints.title',
                    )}
                  </h3>
                  {cmsDatapoints.length === 0 && (
                    <div className="italic text-gray-500 text-sm text-center py-8">
                      {t('global:none')}
                    </div>
                  )}
                  <DatapointsAccordion
                    datapoints={cmsDatapoints}
                    disclosureRequirement={disclosureRequirement!}
                  />
                </div>
                <div className="w-6/12">
                  <DisclosureRequirement document={cmsDisclosureRequirement} />
                </div>
                <div className="w-2/12">
                  {guidelines.length > 0 && (
                    <Guidances guidances={guidelines} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </DisclosureProvider>
      ) : (
        <MessageBox type={MessageBoxType.Error}>
          {t('global:noResult')}
        </MessageBox>
      )}
    </div>
  );
};

function Header({
  cmsDisclosureRequirement,
  datapoints,
}: {
  cmsDisclosureRequirement: GapAnalysisDisclosureRequirement_DisclosureRequirementFragment;
  datapoints: GapAnalysisSummary_DataPointFragment[];
}) {
  const { t } = useTranslation();
  const enabledDatapoints = datapoints.filter((dp) => !dp.disabled);
  let availableDataPoints = enabledDatapoints.filter((dp) => dp.isAvailable);
  return (
    <div className="bg-white">
      <div className="main-content py-8 flex justify-between items-center gap-4">
        <div className="space-y-2 grow">
          <div className="text-gray-500 text-sm">
            {cmsDisclosureRequirement.esrs?.name}
          </div>
          <h1 className="title-h2">
            {cmsDisclosureRequirement.code} {cmsDisclosureRequirement.title}
          </h1>
        </div>
        <div className="w-1/4 space-y-1">
          <div className="flex items-center justify-between gap-2 text-sm">
            <div className="text-gray-500">
              {t(
                'gap_analysis.summary.table.header.datapointAvailability.label',
              )}
            </div>
            <div className="text-gray-500 font-bold">
              {availableDataPoints.length} / {enabledDatapoints.length}
            </div>
          </div>
          <ProgressBar
            value={availableDataPoints.length}
            total={enabledDatapoints.length}
            style={ProgressBarStyles.COLORED}
          />
        </div>
      </div>
    </div>
  );
}
