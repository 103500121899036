import React from 'react';
import { BlocksRenderer as StrapiBlocksRenderer } from '@strapi/blocks-react-renderer';
import { BlocksRenderer } from '../../../cms/renderers/BlocksRenderer';
import { useDisclosure } from './DisclosureContext';
import clsx from 'clsx';

export function BlockWithDatapointsRenderer({
  content,
  datapoints,
}: React.ComponentProps<typeof StrapiBlocksRenderer> & {
  datapoints: string[];
}) {
  const disclosureContext = useDisclosure();
  const isHighlighted = disclosureContext.highlightedDatapointsSlugs.some(
    (slug) => datapoints.includes(slug),
  );
  return (
    <BlocksRenderer
      content={content}
      blocks={{
        paragraph: ({ children }) => (
          <span
            className={clsx(
              'cursor-pointer',
              isHighlighted ? 'bg-yellow-200' : 'bg-yellow-100',
            )}
            onMouseOver={() =>
              disclosureContext.setHighlightedDatapointsSlugs(datapoints)
            }
            onMouseLeave={() =>
              disclosureContext.setHighlightedDatapointsSlugs([])
            }
            onClick={() => disclosureContext.selectDatapointSlug(datapoints[0])}
          >
            {children}
          </span>
        ),
      }}
    />
  );
}
