import { useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { PageTitle } from '../../../components/nav/PageTitle';
import { ProSurvey } from '../../../components/project/survey/ProSurvey';
import { useProjectContext } from '../../../providers/ProjectContextProvider';
import {
  ProSurveyScreen_SurveyFragment,
  useCompanyAbstractQuery,
  useProSurveyScreenQuery,
} from '../../../graphql/generated';
import { LoaderFullscreen } from '../../../components/layout/Loader';
import {
  MessageBox,
  MessageBoxType,
} from '../../../components/layout/MessageBox';

export function ProSurveyScreen() {
  const { surveyId } = useParams();

  const { data, loading } = useProSurveyScreenQuery({
    variables: { surveyId: surveyId || '' },
    skip: !surveyId,
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <LoaderFullscreen />;
  }

  if (data?.survey) {
    return <ProSurveyScreenInner survey={data.survey} />;
  } else {
    return (
      <MessageBox type={MessageBoxType.Error}>
        <p>
          Une erreur est survenue lors de la récupération de la consultation.
        </p>
      </MessageBox>
    );
  }
}

function ProSurveyScreenInner({
  survey,
}: {
  survey: ProSurveyScreen_SurveyFragment;
}) {
  const projectContext = useProjectContext();
  const { data } = useCompanyAbstractQuery({
    variables: {
      id: survey.company?.id || '',
    },
    skip: !survey.company,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (projectContext && !projectContext?.company && data?.company) {
      projectContext.updateCompany(data?.company.id, false);
    }
  }, [data, projectContext]);

  return (
    <>
      <PageTitle title="Consultations" />
      <ProSurvey survey={survey} />
    </>
  );
}
