import React from 'react';
import { DatapointTag_DatapointFragment } from '../../../../../graphql/cms/generated';
import { GapAnalysisDisclosureRequirement_DisclosureRequirementFragment } from '../../../../../graphql/generated';
import { DatapointAccordionItem } from './DatapointAccordionItem';

export function DatapointsAccordion({
  datapoints,
  disclosureRequirement,
}: {
  datapoints: DatapointTag_DatapointFragment[];
  disclosureRequirement: GapAnalysisDisclosureRequirement_DisclosureRequirementFragment;
}) {
  return (
    <div className="space-y-2">
      {datapoints.map((datapoint, index) => {
        const reportDatapoint = disclosureRequirement?.dataPoints?.find(
          (reportDatapoint) => reportDatapoint.referenceSlug === datapoint.slug,
        );
        return (
          <div>
            <DatapointAccordionItem
              datapoint={datapoint}
              key={index}
              reportDatapoint={reportDatapoint}
              disclosureRequirement={disclosureRequirement}
            />
          </div>
        );
      })}
    </div>
  );
}
