import React, { Fragment, useEffect, useState } from 'react';
import { CompanyFieldsFragment, CompanyType } from 'graphql/generated';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import clsx from 'clsx';
import { Loader } from '../generic/Loader';
import { useProjectContext } from '../../providers/ProjectContextProvider';
import { useCurrentUser } from '../../providers/CurrentUserProvider';
import { CompanyLogo } from './project/CompanyLogo';
import { CheckIcon, DropIcon } from '../icons';

export function ProjectSelect() {
  const projectContext = useProjectContext();
  const currentUser = useCurrentUser();

  const [selectedCompanyItem, setSelectedCompanyItem] =
    useState<CompanyFieldsFragment | null>(projectContext?.company || null);

  // Listen to context change to update selected diagnostic
  useEffect(() => {
    if (projectContext?.company) {
      setSelectedCompanyItem(projectContext.company);
    }
  }, [projectContext?.company]);

  const chooseItem = (company: CompanyFieldsFragment) => {
    setSelectedCompanyItem(company);
    if (projectContext) {
      projectContext.updateCompany(company.id, true);
    }
  };

  const companies =
    currentUser?.contributions
      ?.map((contribution) => contribution.contributesTo)
      .filter((company) => company.type === CompanyType.EndUser) || [];

  return companies ? (
    <Listbox value={selectedCompanyItem} onChange={chooseItem}>
      {({ open }) => (
        <div className="relative">
          <ListboxButton className="relative w-full cursor-default rounded-md border py-3 pl-3 pr-10 text-left shadow-sm text-sm flex gap-2 items-center bg-white border-gray-50 hover:border-gray-500">
            {selectedCompanyItem && (
              <CompanyLogo companyId={selectedCompanyItem.id} size={'small'} />
            )}
            <div className="grow flex flex-col gap-0.5">
              <label className="font-normal text-xs text-gray-500">
                Projet
              </label>
              <span className="flex items-center w-full">
                <span
                  className={clsx(
                    'block text-gray-900 max-w-[10rem] truncate text-ellipsis',
                    selectedCompanyItem?.name || 'italic font-medium',
                  )}
                >
                  {selectedCompanyItem?.name || 'Choisir un projet'}
                </span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <DropIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </div>
          </ListboxButton>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <ListboxOptions className="absolute z-50 mt-1 max-h-96 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-sm ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm border border-gray-100">
              {companies
                .toSorted((a, b) => a.name.localeCompare(b.name))
                .map((companyListItem) => (
                  <ListboxOption
                    key={companyListItem.id}
                    className={clsx(
                      'data-[selected]:active data-[focus]:text-white data-[focus]:bg-purple-500',
                      selectedCompanyItem?.id === companyListItem.id &&
                        'bg-purple-50',
                      'listbox-option-purple relative select-none py-2 pl-3 pr-9 cursor-pointer text-gray-900',
                    )}
                    value={companyListItem}
                  >
                    {() => (
                      <div className="flex items-center">
                        <div
                          className={clsx(
                            selectedCompanyItem?.id === companyListItem.id
                              ? 'font-semibold'
                              : 'font-normal',
                            'ml-3 block truncate',
                          )}
                        >
                          {companyListItem.name}
                        </div>
                        {selectedCompanyItem?.id === companyListItem.id && (
                          <span
                            className={clsx(
                              'icon absolute inset-y-0 right-0 flex items-center pr-4 text-purple-500',
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </div>
                    )}
                  </ListboxOption>
                ))}
            </ListboxOptions>
          </Transition>
        </div>
      )}
    </Listbox>
  ) : (
    <div className="w-full flex items-center justify-center gap-2">
      <span className="text-gray-500">Chargement</span>
      <Loader />
    </div>
  );
}
