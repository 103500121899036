import { useTranslation } from '@hooks/useTranslation';
import { SoonBadge } from '../../../components/badges/SoonBadge';
import { AppRoutes } from '../../AppRoutes';

export const enum GAP_ANALYSIS_TABS {
  SUMMARY = 'SUMMARY',
  SYNTHESIS = 'SYNTHESIS',
  CHECKLIST = 'CHECKLIST',
}

export const useGapAnalysisTabs = () => {
  const { t } = useTranslation();

  return [
    {
      key: GAP_ANALYSIS_TABS.SUMMARY,
      name: t('nav:page.gap_analysis.summary'),
      path: AppRoutes.GapAnalysis,
    },
    {
      key: GAP_ANALYSIS_TABS.SYNTHESIS,
      name: <>{t('nav:page.gap_analysis.synthesis')}</>,
      path: AppRoutes.GapAnalysisSynthesis,
    },
    {
      key: GAP_ANALYSIS_TABS.CHECKLIST,
      name: (
        <>
          {t('nav:page.gap_analysis.checklist')} <SoonBadge />
        </>
      ),
      disabled: true,
    },
  ];
};
