import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import { IllustratedExplanationMessage } from '../../../generic/IllustratedExplanationMessage';
import loginIllustration from '../../../../assets/images/illustrations/login.png';
import { CreateChecklistButton } from '../../../task/CreateChecklistButton';
import { Checklist } from '../../../task/Checklist';
import React from 'react';
import { LoaderFullscreen } from '../../../layout/Loader';

export const ProjectMethodologyContent = () => {
  const projectContext = useProjectContext();
  if (!projectContext?.company) {
    return <LoaderFullscreen />;
  }
  if (!projectContext?.company?.checklist?.id) {
    return (
      <div className="grow w-full py-8 px-16 max-w-6xl">
        <IllustratedExplanationMessage
          illustration={
            <img className="w-1/2" src={loginIllustration} alt="" />
          }
          title="Créez une liste de tâches"
          description="Menez votre projet à terme en créant une liste de tâches !"
        >
          <CreateChecklistButton
            companyId={projectContext?.company?.id || ''}
          />
        </IllustratedExplanationMessage>
      </div>
    );
  }

  return (
    <div className="grow bg-gray-50 w-full py-8 px-16">
      {projectContext?.company?.checklist?.id && (
        <div className="max-w-4xl">
          <Checklist checklistId={projectContext.company.checklist.id} />
        </div>
      )}
    </div>
  );
};
