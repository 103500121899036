import { read, utils } from 'xlsx';
import React, { ChangeEvent, useRef, useState } from 'react';
import { NumberCircle } from '../../generic/NumberCircle';
import clsx from 'clsx';
import {
  SurveyQuestionAddInput,
  SurveyQuestionType,
  useImportXlsFile_AddQuestionMutation,
  useImportXlsFile_DeleteQuestionMutation,
  useImportXlsFileMutation,
} from '../../../graphql/generated';
import { useProjectContext } from '../../../providers/ProjectContextProvider';
import { useToast } from '../../layout/Toast';
import { Loader } from '../../generic/Loader';
import { generatePath, useNavigate } from 'react-router-dom';
import StakeDropdown from '../../form/StakeDropdown';
import { getScoreColor } from './MaturityEvaluationRespondModal/MaturityQuestion';
import { CheckIcon, DownloadIcon, PlayIcon } from '../../icons';
import { AppRoutes } from '../../../screens/AppRoutes';

export type XLSRow = {
  Question: string;
  Description: string;
  Type: string;
  Choix1: string;
  Choix2: string;
  Choix3: string;
  Choix4: string;
};

export function ImportXlsFile() {
  const toast = useToast();
  const navigate = useNavigate();
  const projectContext = useProjectContext();

  const [questions, setQuestions] = useState<Partial<SurveyQuestionAddInput>[]>(
    [],
  );

  // const [addEvaluationMaturity] = useAddEvaluationMaturityMutation();
  const [addSurveyMutation] = useImportXlsFileMutation();
  const [deleteQuestionMutation] = useImportXlsFile_DeleteQuestionMutation();
  const [addMaturityEvaluationQuestionMutation] =
    useImportXlsFile_AddQuestionMutation();
  const [isImporting, setIsImporting] = useState(false);

  async function runImport() {
    setIsImporting(true);

    try {
      // Create new survey
      // const survey = await addEvaluationMaturity({
      const survey = await addSurveyMutation({
        variables: {
          input: {
            company: {
              id: projectContext?.company?.id || '',
            },
            name: 'Questionnaire de maturité',
            isTemplate: false,
          },
        },
      });

      if (survey.data?.createMaturityEvaluationFromXLSFile?.questions) {
        // New survey comes with sample question(s), delete them
        await deleteQuestionMutation({
          variables: {
            input: {
              filter: {
                id: {
                  in: survey.data.createMaturityEvaluationFromXLSFile.questions.map(
                    (q) => q.id,
                  ),
                },
              },
            },
          },
        });
      }
      console.log(questions);

      // Create new questions
      await Promise.all(
        questions.map((question) => {
          return addMaturityEvaluationQuestionMutation({
            variables: {
              input: {
                survey: {
                  id:
                    survey.data?.createMaturityEvaluationFromXLSFile?.id || '',
                },
                title: question.title || '',
                description: question.description,
                type: question.type || SurveyQuestionType.MaturityEvaluation,
                position: question.position || 0,
                required: !!question.required,
                choices: question.choices,
                stake: question.stake?.id
                  ? {
                      id: question.stake.id,
                    }
                  : null,
              },
            },
          });
        }),
      );
      toast.openToastWithMessage('Évaluation de maturité créé');
      setIsImporting(false);
      navigate(generatePath(AppRoutes.MaturityEvaluations));
    } catch (err) {
      toast.openToastWithError('Erreur lors de la création.');
      console.error(err);
      setIsImporting(false);
    }
  }

  return (
    <>
      <div className="p-4 rounded-xl bg-blue-50 flex items-center gap-8">
        <UploadFilePlaceholder
          questions={questions}
          setQuestions={setQuestions}
        />
        <div className="space-y-4">
          <h1 className="title-h2">Importer une évaluation de maturité</h1>
          <p className="text-gray-500 text-sm">
            Si vous avez déjà une évaluation de maturité sous forme de fichier,
            vous pouvez l&apos;importer ici. Le format attendu est un{' '}
            <strong>fichier Excel (.xls ou .xlsx)</strong> avec une ligne par
            question, et contenant les colonnes suivantes :
          </p>
          <ol className="list-item ml-4 text-gray-500 text-sm">
            <li className="flex items-center gap-1">
              <NumberCircle number={0} size={8} mode={'alpha'} />
              <strong>Question : </strong> intitulé de la question
            </li>
            <li className="flex items-center gap-1">
              <NumberCircle number={1} size={8} mode={'alpha'} />
              <strong>Description : </strong> description, texte d&apos;aide
            </li>
            <li className="flex items-center gap-1">
              <NumberCircle number={2} size={8} mode={'alpha'} />
              <strong>Type : </strong> &apos;quali&apos; pour une question
              ouverte ou &apos;quanti&apos; pour un choix multiple
            </li>
            <li className="flex items-center gap-1">
              <NumberCircle number={3} size={8} mode={'alpha'} />
              <strong>Niveau de maturité 0 : </strong> intitulé du premier
              niveau de maturité (questions quanti uniquement)
            </li>
            <li className="flex items-center gap-1">
              <NumberCircle number={4} size={8} mode={'alpha'} />
              <strong>Score 0 : </strong> score du premier niveau de maturité
              (questions quanti uniquement)
            </li>
          </ol>
          <p className="text-gray-500  text-sm">
            Répétez les colonnes D et E pour chaque niveau de maturité
          </p>
          <p className="text-gray-500  text-sm">
            Aidez-vous de{' '}
            <a
              href="https://docs.google.com/spreadsheets/d/1RjAPqPDePdIszAFTp2DI5aVH3Pk55trO/edit?usp=sharing&ouid=103430452370545003494&rtpof=true&sd=true"
              target="_blank"
              className="underline font-bold"
              rel="noreferrer"
            >
              ce fichier d&apos;exemple
            </a>
            .
          </p>
        </div>
      </div>
      {questions.length > 0 && (
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <div className="space-y-2">
              <h4>{questions.length} questions trouvées</h4>
              <p className="text-gray-500 text-sm">
                Consultez les et adaptez les valeurs, puis lancez l&apos;import
                pour retrouver votre évaluation dans la liste
              </p>
            </div>
            <button
              className="primary purple"
              onClick={() => void runImport()}
              disabled={isImporting}
            >
              {isImporting ? <Loader /> : <PlayIcon className="w-4 h-4" />}
              <div>Lancer l&apos;import</div>
            </button>
          </div>
          <div className="space-y-2">
            {questions.map((question, index) => (
              <ParsedRow
                key={index}
                question={question}
                questions={questions}
                setQuestions={setQuestions}
                index={index}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
}

const enum XLS_QUESTION_TYPES {
  QUALI = 'quali',
  QUANTI = 'quanti',
  TABLE = 'Table',
  TABLE_MASS = 'table/mass',
  TABLE_VOLUME = 'table/Volume',
  PERCENT = 'percent',
  MONETARY = 'monetary',
  VOLUME = 'volume',
  INTEGER = 'integer',
}

function UploadFilePlaceholder({
  questions,
  setQuestions,
}: {
  questions: Partial<SurveyQuestionAddInput>[];
  setQuestions: (questions: Partial<SurveyQuestionAddInput>[]) => void;
}) {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const toast = useToast();

  function handleClick() {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.files) return;
    const fileUploaded = event.target.files[0];
    readAndParseXlsFile(fileUploaded).catch((e) => {
      console.error(e);
    });
  }

  async function onDrop(e: React.DragEvent<HTMLDivElement>) {
    e.stopPropagation();
    e.preventDefault();
    if (e.dataTransfer?.files[0]) {
      await readAndParseXlsFile(e.dataTransfer?.files[0]);
    }
    return null;
  }

  async function readAndParseXlsFile(file: File) {
    const data = await file.arrayBuffer();
    const workbook = read(data);
    const ws = workbook.Sheets[workbook.SheetNames[0]]; // get the first worksheet
    const content: XLSRow[] = utils.sheet_to_json<XLSRow>(ws); // generate objects

    // Now map the content to SurveyQuestion
    const parsedQuestions = content.reduce(
      (acc: Partial<SurveyQuestionAddInput>[], row) => {
        try {
          const question = mapRowToQuestion(row, acc.length);
          return [...acc, question];
        } catch (err) {
          return acc; // Silently ignore invalid rows
        }
      },
      [],
    );
    setQuestions(parsedQuestions);
    if (!parsedQuestions || parsedQuestions.length === 0) {
      toast.openToastWithError('Aucune question trouvée');
    } else {
      toast.openToastWithMessage(
        `${parsedQuestions.length} questions trouvées`,
      );
    }
  }

  if (questions && questions.length > 0) {
    return (
      <div className="relative shrink-0 bg-white w-56 h-56 border-2 border-dashed rounded-xl border-gray-100 flex flex-col gap-2 items-center justify-center italic text-green-500">
        <CheckIcon className="w-8 h-8" />
        <div>{questions.length} questions trouvées</div>
        <button
          className="unstyled small gray text-gray-500 absolute bottom-2"
          onClick={() => setQuestions([])}
        >
          Recommencer
        </button>
      </div>
    );
  }

  return (
    <div
      className="shrink-0 bg-white w-56 h-56 border-2 border-dashed rounded-xl border-gray-100 flex flex-col gap-2 items-center justify-center italic text-gray-500"
      onDrop={(event) => void onDrop(event)}
      onDragOver={(event) => {
        event.stopPropagation();
        event.preventDefault();
        event.currentTarget.classList.add('border-gray-900');
        event.currentTarget.classList.remove('text-gray-500');
        event.currentTarget.classList.add('text-gray-900');
      }}
      onDragLeave={(event) => {
        event.stopPropagation();
        event.preventDefault();
        event.currentTarget.classList.remove('border-gray-900');
        event.currentTarget.classList.remove('text-gray-900');
        event.currentTarget.classList.add('text-gray-500');
      }}
    >
      <DownloadIcon className="w-8 h-8" />
      <div>Déposez votre fichier ici</div>
      <div>ou</div>
      <button className="unstyled small blue" onClick={handleClick}>
        choisissez un fichier
      </button>
      <input
        type="file"
        onChange={handleChange}
        ref={hiddenFileInput}
        className="hidden"
      />
    </div>
  );
}

function mapRowToQuestion(
  row: XLSRow,
  index: number,
): Partial<SurveyQuestionAddInput> {
  const title = row.Question;
  const description = row.Description;
  const type = mapRowTypeToQuestionType(row.Type);

  if (!type) throw new Error(`Unknown question type ${row.Type}`);

  const choices = [];
  if (row.Choix1) {
    choices.push({ label: row.Choix1, score: 0, position: 0 });
  }
  if (row.Choix2) {
    choices.push({ label: row.Choix2, score: 1, position: 1 });
  }
  if (row.Choix3) {
    choices.push({ label: row.Choix3, score: 2, position: 2 });
  }
  if (row.Choix4) {
    choices.push({ label: row.Choix4, score: 3, position: 3 });
  }
  return {
    title,
    type,
    description,
    choices,
    position: index,
    required: true,
  };
}

function mapRowTypeToQuestionType(rowType: string): SurveyQuestionType | null {
  switch (rowType.toLowerCase()) {
    case XLS_QUESTION_TYPES.QUALI:
      return SurveyQuestionType.ShortText;
    case XLS_QUESTION_TYPES.QUANTI:
      return SurveyQuestionType.MaturityEvaluation;
    case XLS_QUESTION_TYPES.TABLE:
      return SurveyQuestionType.ShortText;
    case XLS_QUESTION_TYPES.TABLE_MASS:
      return SurveyQuestionType.ShortText;
    case XLS_QUESTION_TYPES.TABLE_VOLUME:
      return SurveyQuestionType.ShortText;
    case XLS_QUESTION_TYPES.PERCENT:
      return SurveyQuestionType.Number;
    case XLS_QUESTION_TYPES.MONETARY:
      return SurveyQuestionType.Number;
    case XLS_QUESTION_TYPES.VOLUME:
      return SurveyQuestionType.Number;
    case XLS_QUESTION_TYPES.INTEGER:
      return SurveyQuestionType.Number;
    default:
      return null;
  }
}

function ParsedRow({
  question,
  questions,
  setQuestions,
  index,
}: {
  question: Partial<SurveyQuestionAddInput>;
  questions: Partial<SurveyQuestionAddInput>[];
  setQuestions: (questions: Partial<SurveyQuestionAddInput>[]) => void;
  index: number;
}) {
  const pickStake = (stakeId: string | null) => {
    setQuestions(
      questions.map((question, questionIndex) => {
        if (questionIndex === index) {
          return {
            ...question,
            stake: {
              id: stakeId || '',
            },
          };
        }
        return question;
      }),
    );
  };

  return (
    <div className="bg-white border border-gray-100 rounded-xl shadow-sm p-4 space-y-2">
      <div className="flex items-start gap-2">
        <NumberCircle number={index + 1} size={8} />
        <div className="flex flex-col gap-2 items-stretch w-full">
          <div className="flex justify-between gap-4">
            <div>
              <div className="flex items-start gap-2 justify-between">
                <div className="font-bold">{question.title}</div>
              </div>
              {question.description && (
                <div className="text-gray-500 text-sm">
                  {question.description}
                </div>
              )}
            </div>
            <StakeDropdown
              currentStakeId={question.stake?.id}
              setCurrentStakeId={pickStake}
              isRequired={true}
            />
          </div>

          {(question.choices || [])
            .toSorted((a, b) => (a.score || 0) - (b.score || 0))
            .map((choice, choiceIndex) => (
              <div
                className={clsx(
                  'border border-gray-100 rounded-lg p-2 flex items-start gap-2 shadow-sm cursor-pointer hover:border-gray-900',
                )}
                key={choiceIndex}
              >
                <NumberCircle
                  number={choice.score || 0}
                  size={6}
                  color={getScoreColor(choice.score)}
                />
                <div className="text-sm">{choice.label}</div>
              </div>
            ))}

          {question.type === SurveyQuestionType.ShortText && (
            <textarea
              className="border border-gray-100 rounded-lg p-2 text-sm"
              placeholder="Réponse"
            />
          )}

          {question.type === SurveyQuestionType.Number && (
            <input
              type="number"
              className="border border-gray-100 rounded-lg p-2 text-sm w-40"
            />
          )}
        </div>
      </div>
    </div>
  );
}
