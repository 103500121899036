import {
  SurveyLanguage,
  SurveySettings_DeleteSurveyButtonFragment,
  SurveySettings_SurveyListDocument,
  SurveySettings_SurveySettingsFormFragment,
  SurveyUpdateInput,
  useSurveySettings_SurveyDeleteMutation,
  useSurveySettings_SurveyQuery,
  useSurveySettings_SurveyUpdateMutation,
} from '../../../graphql/generated';
import React, { useState } from 'react';
import { useToast } from '../../layout/Toast';
import { useProjectContext } from '../../../providers/ProjectContextProvider';
import { useModal } from '../../layout/Modal';
import { ConfirmationModal } from '../../modals/ConfirmationModal';
import { useNavigate } from 'react-router-dom';
import StringDropdown, {
  StringDropdownItem,
} from '../../generic/form/StringDropdown';
import { ExportSurveyButton } from './ExportSurveyButton';
import { LoaderFullscreen } from '../../layout/Loader';
import { MessageBox, MessageBoxType } from '../../layout/MessageBox';
import { useTranslation } from '@hooks/useTranslation';
import { GroupedStringDropdownItem } from '../../generic/form/GroupedStringDropdown';
import { GroupedStakeholderSegmentDropdown } from '../stakeholder/dropdown/GroupedStakeholderSegmentDropdown';
import { useGroupedStakeholderSegments } from '../stakeholder/dropdown/useGroupedStakeholderSegments';
import { AppRoutes } from '../../../screens/AppRoutes';

export function SurveySettings({ surveyId }: { surveyId: string }) {
  const query = useSurveySettings_SurveyQuery({
    variables: { id: surveyId },
    fetchPolicy: 'network-only',
  });

  if (query.loading) {
    return <LoaderFullscreen />;
  }

  if (query.error) {
    return (
      <MessageBox type={MessageBoxType.Error}>{query.error.message}</MessageBox>
    );
  }

  if (!query.data) {
    return null;
  }

  const survey = query.data?.survey;

  return <SurveySettingsForm survey={survey} />;
}

function SurveySettingsForm({
  survey,
}: {
  survey: SurveySettings_SurveySettingsFormFragment;
}) {
  const toast = useToast();
  const projectContext = useProjectContext();
  const { t } = useTranslation();

  const [updateSurveyMutation] = useSurveySettings_SurveyUpdateMutation();

  const [surveyUpdateInput, setSurveyUpdateInput] = useState<SurveyUpdateInput>(
    {
      id: survey.id,
      name: survey.name,
      targetThreshold: survey.targetThreshold,
      defaultSegment:
        (survey.defaultSegment && {
          id: survey.defaultSegment?.id,
        }) ||
        null,
      language: survey.language,
    },
  );

  const availableSurveyLanguages = [
    {
      id: SurveyLanguage.Fr,
      label: t('translation:survey.settings.language.fr'),
    },
    {
      id: SurveyLanguage.En,
      label: t('translation:survey.settings.language.en'),
    },
  ];
  const getSurveyLanguageItem = (
    surveyLanguage: SurveyLanguage,
  ): StringDropdownItem | null => {
    const item = availableSurveyLanguages.find(
      (availableItem) =>
        availableItem.id.toLowerCase() === surveyLanguage.toLowerCase(),
    );
    if (item) {
      return item;
    }
    return null;
  };

  const propagateNewSurveyState = (surveyUpdateInput: SurveyUpdateInput) => {
    setSurveyUpdateInput(surveyUpdateInput);
    updateSurvey(surveyUpdateInput);
  };

  const changeSurveyName = (value: string) => {
    propagateNewSurveyState({
      ...surveyUpdateInput,
      name: value,
    });
  };

  const changeSurveyTargetThreshold = (value: string) => {
    propagateNewSurveyState({
      ...surveyUpdateInput,
      targetThreshold: parseInt(value),
    });
  };

  const changeSurveyLanguageItem = (surveyItem: StringDropdownItem) => {
    propagateNewSurveyState({
      ...surveyUpdateInput,
      language: surveyItem.id as SurveyLanguage,
    });
  };

  const changeSurveyDefaultSegment = (
    dropdownItems: GroupedStringDropdownItem[] | null,
  ) => {
    propagateNewSurveyState({
      ...surveyUpdateInput,
      defaultSegment:
        (dropdownItems?.[0] && {
          id: dropdownItems[0].id ?? '',
        }) ||
        null,
    });
  };

  const updateSurvey = (input: SurveyUpdateInput) => {
    updateSurveyMutation({
      variables: {
        input,
      },
    })
      .then(() => {
        toast.openToastWithMessage('Consultation mise à jour');
        projectContext?.refreshCompany();
      })
      .catch((error) => {
        toast.openToastWithError(error.message);
      });
  };
  const { availableItems } = useGroupedStakeholderSegments(
    survey.company?.id || '',
  );

  return (
    <div className="main-content p-8 space-y-8">
      <h2 className="">{t('translation:survey.settings.title')}</h2>
      <div className="w-1/2 flex flex-col gap-8">
        <div>
          <label htmlFor="name" className="form-input-label mb-2">
            {t('translation:survey.settings.name')}
          </label>
          <input
            type="text"
            name="name"
            id="name"
            className="form-input-text"
            value={surveyUpdateInput.name || ''}
            onChange={(e) =>
              setSurveyUpdateInput({
                ...surveyUpdateInput,
                name: e.target.value,
              })
            }
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                changeSurveyName(surveyUpdateInput.name || '');
              }
            }}
            onBlur={(e) => changeSurveyName(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="threshold" className="form-input-label mb-2">
            {t('translation:survey.settings.defaultSegment')}
          </label>
          <GroupedStakeholderSegmentDropdown
            selectedSegmentIds={[survey.defaultSegment?.id ?? '']}
            handleChange={changeSurveyDefaultSegment}
            availableItems={availableItems}
          />
        </div>
        <div>
          <label htmlFor="threshold" className="form-input-label mb-2">
            {t('translation:survey.settings.language.label')}
          </label>
          <StringDropdown
            availableItems={availableSurveyLanguages}
            item={getSurveyLanguageItem(
              surveyUpdateInput.language || SurveyLanguage.Fr,
            )}
            setItem={changeSurveyLanguageItem}
          />
        </div>
        <div>
          <label htmlFor="threshold" className="form-input-label mb-2">
            {t('translation:survey.settings.wantedResponseNumber')}
          </label>
          <input
            type="number"
            min={0}
            name="threshold"
            id="threshold"
            className="form-input-text"
            value={surveyUpdateInput.targetThreshold || '0'}
            onChange={(e) => {
              changeSurveyTargetThreshold(e.target.value);
            }}
          />
        </div>
        <ExportSurveyButton survey={survey} />
        <DeleteSurveyButton survey={survey} />
      </div>
    </div>
  );
}

export function DeleteSurveyButton({
  survey,
}: {
  survey: Omit<SurveySettings_DeleteSurveyButtonFragment, '__typename'>;
}) {
  const modal = useModal();
  const toast = useToast();
  const navigate = useNavigate();
  const projectContext = useProjectContext();
  const { t } = useTranslation();

  const [deleteSurveyMutation] = useSurveySettings_SurveyDeleteMutation({
    refetchQueries: () => [
      {
        query: SurveySettings_SurveyListDocument,
        variables: {
          companyId: projectContext?.company?.id || '',
        },
      },
    ],
  });

  const deleteSurvey = () => {
    deleteSurveyMutation({
      variables: {
        id: survey.id,
      },
    })
      .then((res) => {
        if (res.data?.deleteOneBaseSurvey) {
          toast.openToastWithMessage(t('toast:survey.delete.success'));
          navigate(AppRoutes.Survey);
        } else {
          toast.openToastWithError(t('toast:survey.delete.error'));
        }
      })
      .catch((error) => {
        toast.openToastWithError(error.message);
      });
  };

  return (
    <div className="bg-red-50 p-4 rounded-xl flex items-center justify-between">
      <div className="text-red-900 text-sm">
        {t('translation:survey.settings.delete.help')}
      </div>
      <button
        className="primary danger"
        onClick={() =>
          modal.openModalWithComponent(
            <ConfirmationModal
              message={t('translation:survey.settings.delete.modal.content')}
              callbackIfConfirmed={deleteSurvey}
            />,
            t('translation:survey.settings.delete.modal.title'),
          )
        }
      >
        {t('global:delete')}
      </button>
    </div>
  );
}
