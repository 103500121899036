import Tabs, { TabItemManager, TabsStyles } from '../../nav/Tabs';
import React, { ReactNode, useEffect, useState } from 'react';

export const TabNavigationLayout = <T extends string>({
  tabs,
  activeTab,
  children,
  renderTabs,
}: {
  tabs: TabItemManager['tabs'];
  activeTab: T;
  children: React.ReactNode;
  renderTabs?: (tabsContent: ReactNode) => ReactNode;
}) => {
  const [currentTab, setCurrentTab] = useState(
    tabs.find((t) => t.key === activeTab) || tabs[0],
  );
  useEffect(() => {
    const newActiveTab = tabs.find((t) => t.key === activeTab);
    if (newActiveTab) {
      setCurrentTab(newActiveTab);
    }
  }, [activeTab, tabs]);
  const tabsManager: TabItemManager = {
    tabs,
    currentTab,
    setCurrentTab,
  };
  const TabsContent = (
    <div className="px-16 border-b border-gray-100 w-full">
      <Tabs tabsManager={tabsManager} style={TabsStyles.UNDERLINED} />
    </div>
  );

  return (
    <div>
      {renderTabs ? renderTabs(TabsContent) : TabsContent}
      {children}
    </div>
  );
};
