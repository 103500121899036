import {
  AuthRoles,
  CompanyType,
  CurrentUserDocument,
  JoinCompanyProjectList_ProjectFragment,
  useJoinCompanyProjectListQuery,
  useRegisterAccountMutation,
} from 'graphql/generated';
import { useCurrentUser } from '../../providers/CurrentUserProvider';
import { LoaderFullscreen } from '../layout/Loader';
import React from 'react';
import { useToast } from '../layout/Toast';
import { Loader } from '../generic/Loader';
import { getFrDateFromISOString } from '../../services/DateService';
import { ChevronDownIcon } from '../icons';

export function JoinCompanyProjectList() {
  const currentUser = useCurrentUser();

  const { data, loading } = useJoinCompanyProjectListQuery({
    variables: { companyId: currentUser?.company?.id || '' },
    skip: !currentUser?.company?.id,
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <LoaderFullscreen />;
  }

  const companyProjects = data?.company_list || [];

  const contributesToCompanyIds =
    currentUser?.contributions
      ?.map((contribution) => contribution.contributesTo)
      .filter((company) => company.type === CompanyType.EndUser)
      .map((company) => company.id) || [];

  const joinableProjects = companyProjects
    .toSorted((a, b) => a.name.localeCompare(b.name))
    // remove projects already joined
    .filter((company) => !contributesToCompanyIds.includes(company.id))
    // remove templates
    .filter((company) => company.type === CompanyType.EndUser);
  return (
    <div className="max-w-6xl mt-16 pb-16">
      {joinableProjects && joinableProjects.length > 0 ? (
        <CompaniesList companies={joinableProjects} />
      ) : null}
    </div>
  );
}

function CompaniesList({
  companies,
}: {
  companies: JoinCompanyProjectList_ProjectFragment[];
}) {
  const [displayProjects, setDisplayProjects] = React.useState(false);
  return (
    <table className="w-full">
      <thead>
        {displayProjects ? (
          <tr>
            <th>Rejoignez les autres projets de votre entreprise</th>
            <th className="w-40"></th>
          </tr>
        ) : (
          <tr>
            <th>Rejoignez les autres projets de votre entreprise</th>
            <th></th>
          </tr>
        )}
      </thead>
      <tbody>
        {displayProjects ? (
          companies.map((company) => (
            <CompanyItem company={company} key={company.id} />
          ))
        ) : (
          <tr>
            <td>
              <button
                className="tertiary p-0 text-sm text-gray-500"
                onClick={() => setDisplayProjects(true)}
              >
                <div>Voir les autres projets disponibles</div>
                <div className="tag gray">{companies.length}</div>
                <ChevronDownIcon />
              </button>
            </td>
            <td></td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

function CompanyItem({
  company,
}: {
  company: JoinCompanyProjectList_ProjectFragment;
}) {
  const currentUser = useCurrentUser();
  const toast = useToast();

  const [isJoining, setIsJoining] = React.useState(false);
  const [registerAccount] = useRegisterAccountMutation({
    refetchQueries: () => [{ query: CurrentUserDocument }],
  });
  const join = () => {
    setIsJoining(true);
    registerAccount({
      variables: {
        input: {
          companyId: company.id,
          email: currentUser?.auth?.email || '',
          password: '',
          role: AuthRoles.Coach,
          firstName: '',
          lastName: '',
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage(
          `Vous avez rejoint le projet ${company.name} !`,
        );
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsJoining(false);
      });
  };

  return (
    <tr key={company.id}>
      <td>
        <div>
          <div className="font-bold">{company.name}</div>
          <div className="text-xs text-gray-500 italic font-light">
            Démarré le {getFrDateFromISOString(company.createdAt)}
            {company.createdBy?.firstName &&
              ` par ${company.createdBy?.firstName} ${company.createdBy?.lastName}`}
          </div>
        </div>
      </td>
      <td>
        <div className="flex justify-end">
          <button
            className="primary purple small"
            onClick={join}
            disabled={isJoining}
          >
            {isJoining && <Loader />}
            <div>Rejoindre</div>
          </button>
        </div>
      </td>
    </tr>
  );
}
