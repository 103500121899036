import { Loader } from '../../generic/Loader';
import { useState } from 'react';
import { useEmployeeRemoveMutation } from '../../../graphql/generated';
import { useToast } from '../../layout/Toast';
import { useProjectContext } from '../../../providers/ProjectContextProvider';
import { XIcon } from '../../icons';

export function RemoveUserButton({
  userId,
  companyId,
}: {
  userId: string;
  companyId: string;
}) {
  const projectContext = useProjectContext();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const [employeeRemoveMutation] = useEmployeeRemoveMutation();

  const remove = () => {
    setIsLoading(true);
    employeeRemoveMutation({ variables: { companyId, userId } })
      .then((res) => {
        toast.openToastWithMessage('Utilisateur supprimé');
        if (projectContext) {
          projectContext.refreshCompany();
        }
      })
      .catch((err) => {
        toast.openToastWithMessage(err.message);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <button
      className="unstyled small float-right"
      disabled={isLoading}
      onClick={() => remove()}
    >
      {isLoading ? <Loader /> : <XIcon />}
    </button>
  );
}
