import React from 'react';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import { PageTitle } from '../../../../components/nav/PageTitle';
import { Breadcrumb } from '../../../../components/nav/Breadcrumb';
import { LoaderFullscreen } from '../../../../components/layout/Loader';
import { OpenSurveyTemplateModalButton } from '../../../../components/project/survey/OpenSurveyTemplateModalButton';
import { useProSurveyListScreenQuery } from '../../../../graphql/generated';
import { SurveyList as SurveyListComponent } from '../../../../components/project/survey/SurveyList';
import { generatePath, NavLink } from 'react-router-dom';
import { EyeIcon } from '../../../../components/icons';
import { useTranslation } from '@hooks/useTranslation';
import { ConsultationRawMaterialitiesList } from '../../../../components/project/survey/ConsultationRawMaterialities/ConsultationRawMaterialitiesList';
import { IconTitle } from '../../../../components/heading/iconTitle';
import { withProjectContext } from '../../../../providers/withProjectContext';
import { AppRoutes } from '../../../AppRoutes';
import { TableSkeletonLoader } from '../../../../components/generic/loader/TableSkeletonLoader';

export function SurveyList() {
  const projectContext = useProjectContext();
  const { t } = useTranslation();

  const { data, loading } = useProSurveyListScreenQuery({
    variables: {
      companyId: projectContext?.company?.id ?? '',
    },
    skip: !projectContext?.company?.id,
    fetchPolicy: 'network-only',
  });

  const surveys = data?.surveys || [];
  const consultationRawMaterialities = data?.consultationRawMaterialities || [];

  if (!projectContext?.company || projectContext?.isLoadingCompany) {
    return <LoaderFullscreen />;
  }

  return (
    <>
      <PageTitle title="Consultations" />
      <Breadcrumb />
      <div className="main-content w-full py-8 space-y-8">
        {projectContext?.company && (
          <div className="flex justify-between items-center gap-2">
            <IconTitle>{t('survey.list.title')}</IconTitle>
            <div className="flex gap-2">
              <OpenSurveyTemplateModalButton />
              <GoToResultsPageButton />
            </div>
          </div>
        )}
        {(loading && <TableSkeletonLoader columns={3} rows={10} />) || (
          <>
            <SurveyListComponent surveys={surveys} />
            {consultationRawMaterialities.length > 0 && (
              <ConsultationRawMaterialitiesList
                consultations={consultationRawMaterialities}
              />
            )}
          </>
        )}
      </div>
    </>
  );
}

function GoToResultsPageButton() {
  const { t } = useTranslation();
  return (
    <NavLink to={generatePath(AppRoutes.SurveyResults)}>
      <button className="secondary purple">
        <EyeIcon />
        <span>{t('translation:survey.results.cta')}</span>
      </button>
    </NavLink>
  );
}


export const SurveyListScreen = withProjectContext(SurveyList, { requiredCompany: true });
