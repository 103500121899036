import React from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from '@hooks/useTranslation';
import clsx from 'clsx';
import {
  GapAnalysisSummary_DisclosureRequirementFragment,
  GapAnalysisSummary_Topic_Level1Fragment,
} from '../../../../../graphql/cms/generated';
import { GapAnalysisSummary_StakeFragment } from '../../../../../graphql/generated';
import { ThemeIcon } from '../../../../generic/icon/ThemeIcon';
import { Checkbox, CheckboxColors } from '../../../../form/Checkbox';
import { ChevronDownIcon, ChevronUpIcon } from '../../../../icons';

export type ChooseReportDisclosureRequirementsRow = {
  topic: GapAnalysisSummary_Topic_Level1Fragment;
  stakes: GapAnalysisSummary_StakeFragment[];
  disclosureRequirements: GapAnalysisSummary_DisclosureRequirementFragment[];
};

export type SelectedData = {
  [topicReferenceSlug: string]: {
    topicSelected: boolean;
    disclosureRequirements: string[];
  };
};

export const useChooseReportDisclosureRequirementsColumns = (
  toggleTopicSelection: (referenceSlug: string) => void,
  selectedData: SelectedData,
): Array<ColumnDef<ChooseReportDisclosureRequirementsRow>> => {
  const { t, translateProperty } = useTranslation();
  const columnHelper = createColumnHelper<ChooseReportDisclosureRequirementsRow>();

  return [
    columnHelper.accessor('topic', {
      cell: (data) => {
        const topic = data.getValue();
        const numberOfDRChecked = selectedData[topic.slug]?.disclosureRequirements.length || 0;
        const isChecked = numberOfDRChecked > 0 && selectedData[topic.slug]?.topicSelected || false;
        const expandProps = {
          onClick: () => data.row.toggleExpanded(),
          style: { cursor: 'pointer' },
        };
        const isIndeterminate = numberOfDRChecked > 0 && numberOfDRChecked < topic.disclosure_requirements.length;
        return (
          <div className={'flex    items-center gap-3'}>
            <Checkbox
              color={CheckboxColors.Purple}
              checked={isChecked}
              indeterminate={isIndeterminate}
              onChange={() => toggleTopicSelection(topic.slug)}
            />
            <div {...expandProps}>{topic.name}</div>
          </div>
        );
      },
      header: () => t('gap_analysis.chooseReportTopicModal.table.header.topic.label'),
    }),
    columnHelper.accessor('stakes', {
      cell: (data) => {
        const stakes = data.getValue();
        const expandButton = (data.row.getIsExpanded() && (
          <ChevronUpIcon />
        )) || <ChevronDownIcon />;
        return (
          <div className="flex items-center justify-between">
            {stakes.length > 0 && stakes.map((stake) => (
              // <StakeTag key={stake.id} stake={stake} />
              <div
                className={clsx(
                  'flex items-center gap-3 text-gray-900 font-bold',
                )}
              >
                {stake.pillar.icon && (
                  <div
                    className={`flex justify-center items-center gap-2 p-2 h-8  rounded-full border-2 border-solid text-${stake.isDisabled ? 'gray-300' : '[#282a2c]'} border-${stake.isDisabled ? 'gray-100' : 'gray-900'} bg-${stake.isDisabled ? 'white' : stake.pillar.color}-100`}
                  >
                    <div className="flex items-center gap-2">
                      <ThemeIcon themeIcon={stake.pillar.icon} />
                      <div className={`text-xs font-bold leading-[150%]`}>
                        {translateProperty(stake, 'name')}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )) || <div />}
            <div className={'flex items-center gap-3 text-gray-500'}>
              <a href={'#'} onClick={() => data.row.toggleExpanded()}>
                {expandButton}
              </a>
            </div>
          </div>
        );
      },
      sortDescFirst: false,
      header: () => (
        <span>
          {t('gap_analysis.chooseReportTopicModal.table.header.relatedStakes.label')}
        </span>
      ),
    }),
  ] as Array<ColumnDef<ChooseReportDisclosureRequirementsRow>>;
};

export const prepareData = (
  topics: (GapAnalysisSummary_Topic_Level1Fragment | null)[],
  stakes: GapAnalysisSummary_StakeFragment[],
): ChooseReportDisclosureRequirementsRow[] => {
  const filteredTopics = topics.filter(
    (topic): topic is GapAnalysisSummary_Topic_Level1Fragment =>
      topic !== null && topic !== undefined && topic.disclosure_requirements.length > 0,
  );

  return filteredTopics.map((topic) => {
    const relevantStakes =
      stakes.filter((stake) => stake.topicIds?.includes(topic.documentId)) ??
      [];

    return {
      topic,
      stakes: relevantStakes,
      disclosureRequirements:
        topic.disclosure_requirements.filter(
          (dr): dr is GapAnalysisSummary_DisclosureRequirementFragment =>
            dr !== null && dr !== undefined,
        ) || [],
    };
  });
};
