import React, { ReactNode } from 'react';
import clsx from 'clsx';

export const NoData = ({
  text,
  actions,
}: {
  text: ReactNode;
  actions: [{ key: string; component: ReactNode }];
}) => {
  return (
    <div
      className={clsx(
        'flex flex-col items-center justify-center gap-4 text-gray-500 p-8',
        'rounded-xl border border-gray-100 shadow-sm',
      )}
    >
      {text}
      <div className="flex items-center gap-2">
        {actions.map(({ key, component }) => (
          <div key={key}>{component}</div>
        ))}
      </div>
    </div>
  );
};
