import React, { useMemo } from 'react';
import {
  DoubleMaterialityArrays_AnswerFragment,
  DoubleMaterialityArrays_QuestionFragment,
  useDoubleMaterialityArrays_ReferentialQuery,
} from '../../../../../graphql/generated';
import { MessageBox, MessageBoxType } from '../../../../layout/MessageBox';
import { useProjectContext } from '../../../../../providers/ProjectContextProvider';
import { StakesDoubleMaterialityComments } from './StakesDoubleMaterialityComments';
import { StakesDoubleMaterialityProbabilityArray } from './StakesDoubleMaterialityProbabilityArray';
import { StakesDoubleMaterialitySeverityArray } from './StakesDoubleMaterialitySeverityArray';
import { useTranslation } from '@hooks/useTranslation';

export function DoubleMaterialityArrays({
  question,
  answers,
  isThumbnail,
}: {
  question: DoubleMaterialityArrays_QuestionFragment;
  answers: DoubleMaterialityArrays_AnswerFragment[];
  isThumbnail?: boolean;
}) {
  const { translateProperty } = useTranslation();
  // Fetch stakes
  const projectContext = useProjectContext();
  const companyReferentialDocumentQuery =
    useDoubleMaterialityArrays_ReferentialQuery({
      variables: { id: projectContext?.company?.referential?.id || '' },
      skip: !projectContext?.company?.id,
      fetchPolicy: 'cache-and-network',
    });
  const referential = companyReferentialDocumentQuery.data?.referential;
  const stakes = useMemo(() => {
    if (question.pillar?.id) {
      return (
        referential?.pillars
          .filter((pillar) => pillar.id === question.pillar?.id)
          .flatMap((pillar) => pillar.stakes)
          .toSorted((a, b) =>
            translateProperty(a, 'name').localeCompare(
              translateProperty(b, 'name'),
            ),
          ) || []
      );
    } else {
      return (
        referential?.pillars
          .flatMap((pillar) => pillar.stakes)
          .toSorted((a, b) =>
            translateProperty(a, 'name').localeCompare(
              translateProperty(b, 'name'),
            ),
          ) || []
      );
    }
  }, [question.pillar?.id, referential?.pillars, translateProperty]);

  // TODO: fix preview when in isThumbnail mode (theme modal)
  if (isThumbnail) {
    return (
      <div className="p-4">
        <MessageBox type={MessageBoxType.Info}>
          Fonctionnalité en cours de développement
        </MessageBox>
      </div>
    );
  }

  if (answers.length === 0) {
    return <MessageBox type={MessageBoxType.Error}>Aucune réponse</MessageBox>;
  }

  return (
    <div className="space-y-8">
      <StakesDoubleMaterialitySeverityArray
        stakes={stakes}
        question={question}
        answers={answers}
      />
      <StakesDoubleMaterialityProbabilityArray
        stakes={stakes}
        question={question}
        answers={answers}
      />
      <StakesDoubleMaterialityComments stakes={stakes} answers={answers} />
    </div>
  );
}
