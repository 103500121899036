import {
  AuthRoles,
  CompanyFieldsFragment,
  ContributionType,
  ContributorFieldsFragment,
  useAuthQuery,
} from 'graphql/generated';
import React from 'react';
import { UserProfileInitials } from '../../user/UserProfileInitials';
import { CopyTextToClipboardButton } from '../../generic/CopyTextToClipboardButton';
import { getInvitationLink } from '../../../services/CompanyService';
import { useCurrentUser } from '../../../providers/CurrentUserProvider';
import { HelpTooltip } from '../../generic/HelpTooltip';
import { RemoveContributorButton } from './RemoveContributorButton';

export function Coaches({ company }: { company: CompanyFieldsFragment }) {
  const currentUser = useCurrentUser();
  const coachInvitationLink = getInvitationLink(company.id, AuthRoles.Coach);
  const companyCoaches =
    company.contributors?.filter(
      (contributor) => contributor.type === ContributionType.Coach,
    ) || [];

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <h2 className="title-h4">Coach</h2>
          <div className="text-gray-500">
            Celles et ceux en charge de produire le diagnostic.
          </div>
          <HelpTooltip>
            <p className="text-sm font-normal">
              Il·elles ont tous les droits d’édition et d’administration.
            </p>
          </HelpTooltip>
        </div>
        <CopyTextToClipboardButton
          buttonLabel="Copier le lien d'invitation coach"
          textToCopy={coachInvitationLink}
          className="purple small"
        />
      </div>
      <div className="grid grid-cols-3 gap-4">
        {companyCoaches.map((coach) => (
          <CoachItem coach={coach} key={coach.id} companyId={company.id} />
        ))}
      </div>
    </div>
  );
}

function CoachItem({
  coach,
  companyId,
}: {
  coach: ContributorFieldsFragment;
  companyId: string;
}) {
  const { data } = useAuthQuery({
    variables: {
      id: coach.user.authId || '',
    },
    skip: !coach.user.authId,
  });

  return (
    <div className="bg-purple-50 rounded-xl px-4 py-4 space-y-4">
      <div className="flex flex-col lg:flex-row gap-4 items-center justify-between">
        <UserProfileInitials user={coach.user} />
        <div className="space-y-0.5 grow">
          <div className="text-gray-900 font-bold text-center lg:text-left">
            {coach.user.firstName} {coach.user.lastName}
          </div>
          <div className="text-gray-500 text-sm max-w-[200px] truncate text-ellipsis">
            {data?.auth.email}
          </div>
        </div>
        <RemoveContributorButton
          contributionId={coach.id}
          companyId={companyId}
        />
      </div>
    </div>
  );
}
