import React from 'react';
import clsx from 'clsx';
import { LoaderFullscreen } from '../../layout/Loader';
import { useProCompanyMaxProjectsCountProgressBarQuery } from '../../../graphql/generated';

export function ProCompanyMaxProjectsCountProgressBar({
  companyId,
}: {
  companyId: string;
}) {
  const companyMaxProjectsCountQuery =
    useProCompanyMaxProjectsCountProgressBarQuery({
      variables: { companyId: companyId },
    });

  if (companyMaxProjectsCountQuery.loading) {
    return <LoaderFullscreen />;
  }

  const licenseUsage = companyMaxProjectsCountQuery.data?.company.licenseUsage;
  const maxProjectsCount = licenseUsage?.maxProjectsCount || 0;
  const projectsCount = licenseUsage?.projectsCount || 0;
  const isUnlimited = licenseUsage?.isUnlimited;
  const percentage = isUnlimited
    ? 100
    : Math.min((projectsCount / maxProjectsCount) * 100, 100);
  return (
    <div className="flex items-center gap-2 w-full">
      <div className="text-sm text-gray-500 shrink-0">
        {projectsCount}/{isUnlimited ? '∞' : maxProjectsCount} projets
      </div>

      <div className={clsx('w-full flex items-center justify-between gap-4')}>
        <div className="flex items-center bg-white rounded-md h-3 shrink-0 w-full">
          <div className="rounded-md bg-white w-full flex justify-between items-center relative">
            {/* Gray bar */}
            <div
              className={clsx(
                'absolute h-3 rounded-lg border-white border-2 flex justify-between items-center w-full bg-gray-100',
              )}
            ></div>

            {/* Colored bar */}
            <div
              className={clsx(
                'absolute h-3 rounded-lg border-white border-2 flex justify-between items-center',
                !isUnlimited && projectsCount > maxProjectsCount
                  ? 'bg-red-500'
                  : 'bg-green-500',
              )}
              style={{ width: `${Math.max(percentage, 5)}%` }} // 5% is the minimum width to avoid weird rendering
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}
