import { useCurrentUser } from '../../../../providers/CurrentUserProvider';
import { LoaderFullscreen } from '../../../../components/layout/Loader';
import { PageTitle } from '../../../../components/nav/PageTitle';
import React from 'react';
import { ProUsersList } from '../../../../components/project/project/ProUsersList';
import { useCompanyQuery } from '../../../../graphql/generated';
import { Breadcrumb } from '../../../../components/nav/Breadcrumb';

export function CoachsSettingsScreen() {
  const currentUser = useCurrentUser();

  // Get full company data
  const { data, loading } = useCompanyQuery({
    variables: {
      id: currentUser?.company?.id || '',
    },
    skip: !currentUser?.company?.id,
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <LoaderFullscreen />;
  }

  if (data?.company) {
    return (
      <div className="">
        <PageTitle title="Gérer les coachs" />
        <Breadcrumb />
        <ProUsersList
          users={data.company.employees}
          companyId={data.company.id}
        />
      </div>
    );
  } else {
    return <LoaderFullscreen />;
  }
}
