import { Outlet, useLocation } from 'react-router-dom';
import { ErrorBoundary } from 'components/generic/ErrorBoundary';
import { LeftNav } from '../nav/LeftNav/LeftNav';
import { LeftDrawer, LeftDrawerProvider } from './LeftDrawer';
import React from 'react';
import clsx from 'clsx';
import { useNavContext } from '../../providers/NavContextProvider';
import { RightDrawerProvider } from './RightDrawer';

export function AdminLayout() {
  const location = useLocation();
  const navContext = useNavContext();

  return (
    <div className="flex h-full w-full">
      <ErrorBoundary key={location.pathname}>
        <RightDrawerProvider>
          <LeftDrawerProvider>
            <LeftNav />
            <LeftDrawer />
            <main
              className={clsx(
                'flex flex-col w-full h-screen overflow-y-auto bg-white transition-all duration-300 ease-in-out',
                navContext?.isNavCollapsed
                  ? 'pl-[0px]'
                  : 'pl-[230px] xl:pl-[280px]',
              )}
            >
              <ErrorBoundary key={location.pathname}>
                <Outlet />
              </ErrorBoundary>
            </main>
          </LeftDrawerProvider>
        </RightDrawerProvider>
      </ErrorBoundary>
    </div>
  );
}
