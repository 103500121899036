import React, { useEffect } from 'react';
import { useTranslation } from '@hooks/useTranslation';
import { withProjectContext } from '../../../../../providers/withProjectContext';
import { GapAnalysisDisclosureRequirement } from '../../../../../components/project/gapAnalysis/disclosureRequirement/GapAnalysisDisclosureRequirement';
import { withRouter } from '../../../../withRouter';
import { useNavContext } from '../../../../../providers/NavContextProvider';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from '../../../../AppRoutes';

type ProGapAnalysisDisclosureRequirementScreenProps = {
  params?: {
    referenceSlug: string;
  };
};

const ProGapAnalysisDisclosureRequirement = withRouter<ProGapAnalysisDisclosureRequirementScreenProps>(({ params: { referenceSlug } }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setTitle, setActions, setOnClose } = useNavContext();

  useEffect(() => {
    setOnClose(() => () => {
      navigate(AppRoutes.GapAnalysis);
    });
  }, [setTitle, setActions, setOnClose, navigate, t]);

  return (
    <>
      <GapAnalysisDisclosureRequirement referenceSlug={referenceSlug} />
    </>
  );
});

export const ProGapAnalysisDisclosureRequirementScreen = withProjectContext(
  ProGapAnalysisDisclosureRequirement,
  {
    requiredCompany: true,
  },
);
