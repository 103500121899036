import React from 'react';
import { useModal } from '../../../../../layout/Modal';
import { useTranslation } from '@hooks/useTranslation';
import {
  IroComment_IroFragment,
  useUpdateIroCommentMutation,
} from '../../../../../../graphql/generated';
import { MessageFillIcon, MessageIcon } from '../../../../../icons';
import { CommentForm } from '../../../../../generic/form/CommentForm';
import { useToast } from '../../../../../layout/Toast';

export function IroComment({
  iro: { id, comment },
}: {
  iro: IroComment_IroFragment;
}) {
  const { t } = useTranslation();
  const modal = useModal();
  const toast = useToast();
  const [update] = useUpdateIroCommentMutation();

  const openCommentModal = () => {
    modal.openModalWithComponent(
      <CommentForm
        initialComment={comment}
        mutate={(comment) =>
          update({
            variables: {
              input: {
                id: id,
                comment,
              },
            },
          })
            .then(() => {
              modal.closeModal();
              toast.openToastWithMessage(t('toast:iro.comment.success'));
            })
            .catch(() => {
              toast.openToastWithMessage(t('toast:iro.comment.error'));
            })
        }
        cta={t('iro.comment.cta')}
      />,
      t('iro.comment.modal_title'),
      false,
      true,
      'w-3/12',
    );
  };

  return (
    <button className="unstyled" onClick={() => openCommentModal()}>
      {comment ? (
        <MessageFillIcon className="w-4 h-4 text-purple-500" />
      ) : (
        <MessageIcon className="w-4 h-4 text-gray-300" />
      )}
    </button>
  );
}
