import { useProjectContext } from '../../../providers/ProjectContextProvider';
import { useTranslation } from '@hooks/useTranslation';
import { FeatureNames, StandardEnum } from '../../../graphql/generated';
import { useFeatureEnabled } from '@hooks/useFeatureEnabled';
import { ProjectSelect } from '../../project/ProjectSelect';
import { NavItem } from './NavItem';
import {
  CalendarIcon,
  GapAnalysisIcon,
  MaterialityIcon,
  MaturityIcon,
  PriorityUpIcon,
  RoadmapIcon,
  StakesIcon,
  SurveyIcon,
  UserIcon,
} from '../../icons';
import { RequiredBadge } from '../../badges/RequiredBadge';
import { NavSection } from './NavSection';
import { SoonBadge } from '../../badges/SoonBadge';
import { CreateNoteButton } from '../../project/notebook/CreateNoteButton';
import React from 'react';
import { isCoachUser } from '../../../services/CompanyService';
import { useCurrentUser } from '../../../providers/CurrentUserProvider';
import { AppRoutes } from '../../../screens/AppRoutes';

export const NavItems = () => {
  const projectContext = useProjectContext();

  const hasReferential = projectContext?.company?.referential !== null;
  const { t } = useTranslation();

  const isCSRDenabled =
    (
      projectContext?.company?.reports?.map(
        (report) => report.standard === StandardEnum.Csrd,
      ) ?? []
    ).length > 0;
  const isGapAnalysisFeatureEnabled = useFeatureEnabled(
    FeatureNames.GapAnalysis,
  );
  const currentUser = useCurrentUser();
  const isCoach = isCoachUser(currentUser);

  return (
    <div className="flex flex-col">
      {isCoach && <ProjectSelect />}
      {projectContext?.company && (
        <div className="mt-4 space-y-0.5">
          <NavItem
            name={t('nav:page.project_management')}
            Icon={CalendarIcon}
            href={AppRoutes.Project}
          />
          <NavItem
            name={t('nav:page.referential')}
            Icon={StakesIcon}
            href={AppRoutes.Stakes}
            badge={!hasReferential && <RequiredBadge />}
          />
          <NavItem
            name={t('nav:page.stakeholder_mapping')}
            Icon={UserIcon}
            href={AppRoutes.StakeholderMapping}
          />
          <NavItem
            name={t('nav:page.iro_rating')}
            Icon={StakesIcon}
            href={AppRoutes.Iro}
          />
          <NavSection label={t('nav:leftNav.section.data_collect')}>
            <NavItem
              name={t('nav:page.maturity_evaluation')}
              Icon={MaturityIcon}
              href={AppRoutes.MaturityEvaluations}
            />
            <NavItem
              name={t('nav:page.surveys')}
              Icon={SurveyIcon}
              href={AppRoutes.Survey}
            />
            {isCSRDenabled && (
              <NavItem
                name={t('nav:page.gap_analysis.summary')}
                Icon={GapAnalysisIcon}
                href={
                  (isGapAnalysisFeatureEnabled && AppRoutes.GapAnalysis) || '#'
                }
                badge={!isGapAnalysisFeatureEnabled && <SoonBadge />}
              />
            )}
          </NavSection>
          <NavSection label={t('nav:leftNav.section.publications')}>
            <NavItem
              name={t('nav:page.maturity_score')}
              Icon={MaturityIcon}
              href={AppRoutes.PublicationMaturity}
            />
            <NavItem
              name={t('nav:page.materiality_matrix')}
              Icon={MaterialityIcon}
              href={AppRoutes.PublicationMaterialityMatrix}
            />
            <NavItem
              name={t('nav:page.double_materiality_matrix')}
              Icon={MaterialityIcon}
              href={AppRoutes.PublicationDoubleMaterialityMatrixIro}
            />
            <NavItem
              name={t('nav:page.prioritization_matrix')}
              Icon={PriorityUpIcon}
              href={AppRoutes.PublicationPrioritizationMatrix}
            />
            <NavItem
              name={t('nav:page.roadmap')}
              Icon={RoadmapIcon}
              href={AppRoutes.Roadmap}
            />
          </NavSection>
          {projectContext?.company?.notebook && (
            <div className="py-4">
              <CreateNoteButton
                notebook={projectContext.company.notebook}
                className="w-full secondary purple small"
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
