import {
  ConsultationRawMaterialitySettingsFormFragment,
  UpdateConsultationRawMaterialityInput,
  useConsultationRawMaterialitySettingsQuery,
  useUpdateConsultationRawMaterialitySettingsMutation,
} from '../../../../graphql/generated';
import React, { useState } from 'react';
import { useToast } from '../../../layout/Toast';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import { LoaderFullscreen } from '../../../layout/Loader';
import { MessageBox, MessageBoxType } from '../../../layout/MessageBox';
import { useTranslation } from '@hooks/useTranslation';
import { GroupedStringDropdownItem } from '../../../generic/form/GroupedStringDropdown';
import { GroupedStakeholderSegmentDropdown } from '../../stakeholder/dropdown/GroupedStakeholderSegmentDropdown';
import { useGroupedStakeholderSegments } from '../../stakeholder/dropdown/useGroupedStakeholderSegments';
import { DeleteSurveyButton } from '../SurveySettings';

export const ProConsultationRawMaterialitySettings = ({
  consultationId,
}: {
  consultationId: string;
}) => {
  const query = useConsultationRawMaterialitySettingsQuery({
    variables: { id: consultationId },
    fetchPolicy: 'network-only',
  });

  if (query.loading) {
    return <LoaderFullscreen />;
  }

  if (query.error) {
    return (
      <MessageBox type={MessageBoxType.Error}>{query.error.message}</MessageBox>
    );
  }

  if (!query.data) {
    return null;
  }

  const consultation = query.data?.consultationRawMateriality;

  return <ConsultationRawMaterialitySettingsForm consultation={consultation} />;
};

function ConsultationRawMaterialitySettingsForm({
  consultation,
}: {
  consultation: ConsultationRawMaterialitySettingsFormFragment;
}) {
  const toast = useToast();
  const projectContext = useProjectContext();
  const { t } = useTranslation();

  const [updateConsultationMutation] =
    useUpdateConsultationRawMaterialitySettingsMutation();

  const [consultationUpdateInput, setConsultationUpdateInput] =
    useState<UpdateConsultationRawMaterialityInput>({
      name: consultation.name,
      numberOfRespondent: consultation.numberOfRespondent ?? 0,
      defaultSegment:
        (consultation.defaultSegment && {
          id: consultation.defaultSegment?.id,
        }) ||
        null,
    });

  const propagateNewSurveyState = (
    consultationUpdateInput: UpdateConsultationRawMaterialityInput,
  ) => {
    setConsultationUpdateInput(consultationUpdateInput);
    updateConsultation(consultationUpdateInput);
  };

  const changeSurveyName = (value: string) => {
    propagateNewSurveyState({
      ...consultationUpdateInput,
      name: value,
    });
  };

  const changeNumberOfRespondent = (value: string) => {
    propagateNewSurveyState({
      ...consultationUpdateInput,
      numberOfRespondent: parseInt(value),
    });
  };

  const changeSurveyDefaultSegment = (
    dropdownItems: GroupedStringDropdownItem[] | null,
  ) => {
    propagateNewSurveyState({
      ...consultationUpdateInput,
      defaultSegment:
        (dropdownItems?.[0] && {
          id: dropdownItems[0].id ?? '',
        }) ||
        null,
    });
  };

  const updateConsultation = (input: UpdateConsultationRawMaterialityInput) => {
    updateConsultationMutation({
      variables: {
        input: {
          id: consultation.id,
          update: input,
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage('Consultation mise à jour');
        projectContext?.refreshCompany();
      })
      .catch((error) => {
        toast.openToastWithError(error.message);
      });
  };
  const { availableItems } = useGroupedStakeholderSegments(
    consultation.company?.id || '',
  );

  return (
    <div className="w-1/3 mx-auto p-8 space-y-8">
      <h2 className="">
        {t('translation:consultationRawMateriality.settings.title')}
      </h2>
      <div className="flex flex-col gap-8">
        <div>
          <label htmlFor="name" className="form-input-label mb-2">
            {t('translation:consultationRawMateriality.settings.name')}
          </label>
          <input
            type="text"
            name="name"
            id="name"
            className="form-input-text"
            value={consultationUpdateInput.name || ''}
            onChange={(e) =>
              setConsultationUpdateInput({
                ...consultationUpdateInput,
                name: e.target.value,
              })
            }
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                changeSurveyName(consultationUpdateInput.name || '');
              }
            }}
            onBlur={(e) => changeSurveyName(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="defaultSegment" className="form-input-label mb-2">
            {t(
              'translation:consultationRawMateriality.settings.defaultSegment',
            )}
          </label>
          <GroupedStakeholderSegmentDropdown
            selectedSegmentIds={[consultation.defaultSegment?.id ?? '']}
            handleChange={changeSurveyDefaultSegment}
            availableItems={availableItems}
          />
        </div>
        <div>
          <label htmlFor="numberOfRespondent" className="form-input-label mb-2">
            {t(
              'translation:consultationRawMateriality.settings.numberOfRespondent',
            )}
          </label>
          <input
            type="number"
            min={0}
            name="numberOfRespondent"
            id="numberOfRespondent"
            className="form-input-text"
            value={consultationUpdateInput.numberOfRespondent || '0'}
            onChange={(e) => {
              changeNumberOfRespondent(e.target.value);
            }}
          />
        </div>
        <DeleteSurveyButton survey={consultation} />
      </div>
    </div>
  );
}
