import React, { useState } from 'react';
import RichTextEditorTextarea from './RichTextEditorTextarea';
import { Loader } from '../Loader';

interface CommentFormProps {
  mutate: (comment: string) => Promise<void>;
  cta?: string;
  initialComment?: string | null;
}

export const CommentForm = ({ cta, initialComment, mutate }: CommentFormProps) => {
  const [comment, setComment] = useState(initialComment || '');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = () => {
    setIsLoading(true);
    mutate(comment)
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div
      className="flex flex-col items-stretch gap-4"
    >
      <RichTextEditorTextarea
        inlined={true}
        value={comment}
        onChange={(value) => setComment(value)}
      />
      <button
        className="primary small"
        onClick={handleSubmit}
        disabled={isLoading}
      >
        {isLoading && <Loader />}
        {cta}
      </button>
    </div>
  );
};
