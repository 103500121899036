import { ReactNode, useEffect } from 'react';
import { useCurrentAuth } from '../providers/CurrentAuthProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCurrentUser } from '../providers/CurrentUserProvider';
import { isCoachUser } from '../services/CompanyService';
import { MessageBox, MessageBoxType } from '../components/layout/MessageBox';
import { LoaderFullscreen } from '../components/layout/Loader';
import { useTranslation } from '@hooks/useTranslation';

export function ProOnlyGuard({ children }: { children: ReactNode }) {
  const location = useLocation();
  const auth = useCurrentAuth();
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const destination = encodeURIComponent(
    `${location.pathname}${location.search}`,
  );
  const loginUrl = `/login?destination=${destination}`;

  useEffect(() => {
    if (auth === null) {
      navigate(loginUrl, { replace: true });
    }
  }, [auth, navigate, loginUrl]);

  if (!auth || !currentUser) {
    return (
      <div className="h-screen w-full">
        <LoaderFullscreen />
      </div>
    );
  }

  if (isCoachUser(currentUser)) {
    return <>{children}</>;
  } else {
    return (
      <div className="h-screen w-full flex items-center justify-center">
        <div className="p-32 flex flex-col justify-center items-center gap-16 max-w-xl">
          <MessageBox type={MessageBoxType.Error}>
            {t('screen.accessDenied')}
          </MessageBox>
          <button className="primary" onClick={() => navigate('/')}>
            {t('global:backToHome')}
          </button>
        </div>
      </div>
    );
  }
}
