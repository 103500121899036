import { Users } from '../Users';
import React from 'react';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';

export const ProjectUsersContent = () => {
  const projectContext = useProjectContext();

  return (
    projectContext?.company && (
      <div className="w-full py-8 px-16 max-w-7xl">
        <Users company={projectContext?.company} />
      </div>
    )
  );
};
