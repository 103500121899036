import React from 'react';
import { ProjectLayout } from '../../components/layout/ProjectLayout';
import { HomeScreen } from '../project/home/HomeScreen';
import { CoachsSettingsScreen } from '../project/coach/settings/CoachsSettingsScreen';
import { CompanySettingsScreen } from '../project/coach/settings/CompanySettingsScreen';
import { TemplatesSettingsScreen } from '../project/coach/settings/TemplatesSettingsScreen';
import { ProActionEditScreen } from '../project/publications/roadmap/ProActionEditScreen';
import { ReferentialScreen } from '../project/referential/ReferentialScreen';
import { IroScreen } from '../project/iro/evaluation/IroScreen';
import { PrioritizationMatrixScreen } from '../project/publications/prioritizationMatrix/PrioritizationMatrixScreen';
import { MaterialityMatrixScreen } from '../project/publications/MaterialityMatrixScreen';
import { ProRoadmapScreen } from '../project/publications/roadmap/ProRoadmapScreen';
import { ProActionScreen } from '../project/publications/roadmap/ProActionScreen';
import { SurveyListScreen } from '../project/survey/SurveyList/SurveyListScreen';
import { ProSurveyResultsScreen } from '../project/survey/ProSurveyResultsScreen';
import { ProSurveyScreen } from '../project/survey/ProSurveyScreen';
import { ProStakeholderScreen } from '../project/stakeholder/ProStakeholderScreen';
import { IroSummaryScreen } from '../project/iro/summary/IroSummaryScreen';
import { useTranslation } from '@hooks/useTranslation';
import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';
import { DoubleMaterialityMatrixIroScreen } from '../project/publications/doubleMaterialityMatrix/DoubleMaterialityMatrixIroScreen';
import { ProConsultationRawMaterialityScreen } from '../project/survey/ConsultationRawMateriality/ProConsultationRawMaterialityScreen';
import { ProGapAnalysisSummaryScreen } from '../project/gapAnalysis/summary/ProGapAnalysisSummaryScreen';
import { ProGapAnalysisDisclosureRequirementScreen } from '../project/gapAnalysis/disclosureRequirement/[referenceSlug]/ProGapAnalysisDisclosureRequirementScreen';
import { FocusLayout } from '../../components/layout/FocusLayout';
import { ProGapAnalysisSynthesisScreen } from '../project/gapAnalysis/synthesis/ProGapAnalysisSynthesisScreen';
import { LoggedInOnlyGuard } from '../../guards/LoggedInOnlyGuard';
import { ProOnlyGuard } from '../../guards/ProOnlyGuard';
import { AppRoutes } from '../AppRoutes';
import { ProjectNotebookScreen } from '../project/project/ProjectNotebookScreen';
import { ProjectSettingsScreen } from '../project/project/ProjectSettingsScreen';
import { ProjectCompanyScreen } from '../project/project/ProjectCompanyScreen';
import { ProjectUsersScreen } from '../project/project/ProjectUsersScreen';
import { ProjectMethodologyScreen } from '../project/project/ProjectMethodologyScreen';
import { MaturityScreen } from '../project/publications/MaturityScreen';
import { MaturityEvaluationsListScreen } from '../project/maturity/MaturityEvaluationsListScreen';
import { MaturityEvaluationImportScreen } from '../project/maturity/MaturityEvaluationImportScreen';
import { MaturityEvaluationScreen } from '../project/maturity/MaturityEvaluationScreen';
import { MaturityEvaluationAssignScreen } from '../project/maturity/MaturityEvaluationAssignScreen';
import { DoubleMaterialityMatrixStakeholderScreen } from '../project/publications/doubleMaterialityMatrix/DoubleMaterialityMatrixStakeholderScreen';

export const useAppRouteConfig = (): BreadcrumbsRoute[] => {
  const { t } = useTranslation();

  return [
    {
      path: AppRoutes.Home,
      element: (
        <LoggedInOnlyGuard>
          <ProjectLayout />
        </LoggedInOnlyGuard>
      ),
      children: [
        {
          index: true,
          breadcrumb: null,
          element: <HomeScreen />,
        },
        {
          path: AppRoutes.Stakes,
          element: <ReferentialScreen />,
          breadcrumb: t('nav:page.referential'),
        },
        {
          path: AppRoutes.Iro,
          children: [
            {
              index: true,
              element: <IroSummaryScreen />,
              breadcrumb: t('nav:page.iro_rating'),
            },
            {
              path: AppRoutes.IroRating,
              breadcrumb: '',
              children: [
                {
                  index: true,
                  element: <IroScreen />,
                  breadcrumb: t('screen.iro.iro_rating'),
                },
              ],
            },
          ],
        },
        {
          path: AppRoutes.GapAnalysis,
          children: [
            {
              index: true,
              element: <ProGapAnalysisSummaryScreen />,
              breadcrumb: t('nav:page.gap_analysis.summary'),
            },
            {
              element: <ProGapAnalysisSynthesisScreen />,
              breadcrumb: t('nav:page.gap_analysis.synthesis'),
              path: AppRoutes.GapAnalysisSynthesis,
            },
            {
              path: AppRoutes.GapAnalysisDisclosureRequirement,
              element: (
                <FocusLayout>
                  <ProGapAnalysisDisclosureRequirementScreen />
                </FocusLayout>
              ),
              breadcrumb: t('nav:page.gap_analysis.summary'),
            },
          ],
        },
        {
          path: AppRoutes.StakeholderMapping,
          element: <ProStakeholderScreen />,
          breadcrumb: t('nav:page.stakeholder_mapping'),
        },
        {
          path: AppRoutes.Project,
          breadcrumb: null,
          children: [
            {
              index: true,
              element: <ProjectMethodologyScreen />,
              breadcrumb: null,
            },
            {
              path: AppRoutes.ProjectNotebook,
              element: <ProjectNotebookScreen />,
              breadcrumb: null,
            },
            {
              path: AppRoutes.ProjectCompany,
              element: <ProjectCompanyScreen />,
              breadcrumb: null,
            },
            {
              path: AppRoutes.ProjectUsers,
              element: <ProjectUsersScreen />,
              breadcrumb: null,
            },
            {
              path: AppRoutes.ProjectSettings,
              element: <ProjectSettingsScreen />,
              breadcrumb: null,
            },
          ],
        },
        {
          path: AppRoutes.Publication,
          breadcrumb: null,
          children: [
            {
              path: AppRoutes.PublicationMaturity,
              breadcrumb: t('nav:page.maturity_score'),
              element: <MaturityScreen />,
            },
            {
              path: AppRoutes.PublicationPrioritizationMatrix,
              breadcrumb: t('nav:page.prioritization_matrix'),
              element: <PrioritizationMatrixScreen />,
            },
            {
              path: AppRoutes.PublicationMaterialityMatrix,
              breadcrumb: t('nav:page.materiality_matrix'),
              element: <MaterialityMatrixScreen />,
            },
            {
              path: AppRoutes.PublicationDoubleMaterialityMatrixIro,
              breadcrumb: t('nav:page.double_materiality_matrix'),
              element: <DoubleMaterialityMatrixIroScreen />,
            },
            {
              path: AppRoutes.PublicationDoubleMaterialityMatrixStakeholder,
              breadcrumb: t('nav:page.double_materiality_matrix'),
              element: <DoubleMaterialityMatrixStakeholderScreen />,
            },
          ],
        },
        {
          path: AppRoutes.Roadmap,
          children: [
            {
              index: true,
              breadcrumb: t('nav:page.roadmap'),
              element: <ProRoadmapScreen />,
            },
            {
              path: AppRoutes.RoadmapAction,
              breadcrumb: null,
              element: <ProActionScreen />,
            },
            {
              path: AppRoutes.RoadmapActionEdit,
              breadcrumb: null,
              element: <ProActionEditScreen />,
            },
          ],
        },
        {
          path: AppRoutes.Survey,
          breadcrumb: null,
          children: [
            {
              path: AppRoutes.Survey,
              breadcrumb: null,
              children: [
                {
                  index: true,
                  breadcrumb: t('nav:page.surveys'),
                  element: <SurveyListScreen />,
                },
                {
                  path: AppRoutes.SurveyResults,
                  children: [
                    {
                      index: true,
                      element: <ProSurveyResultsScreen />,
                      breadcrumb: t('nav:page.surveys'),
                    },
                  ],
                },
                {
                  path: AppRoutes.SurveyItem,
                  children: [
                    {
                      index: true,
                      element: <ProSurveyScreen />,
                      breadcrumb: null,
                    },
                  ],
                },
                {
                  path: AppRoutes.SurveyTab,
                  children: [
                    {
                      index: true,
                      element: <ProSurveyScreen />,
                      breadcrumb: null,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: AppRoutes.ConsultationRawMaterialities,
          breadcrumb: null,
          children: [
            {
              index: true,
              breadcrumb: t('nav:page.surveys'),
              element: <ProConsultationRawMaterialityScreen />,
            },
          ],
        },
        {
          path: AppRoutes.MaturityEvaluations,
          breadcrumb: null,
          children: [
            {
              index: true,
              breadcrumb: t('maturity.evaluation.title'),
              element: <MaturityEvaluationsListScreen />,
            },
            {
              path: AppRoutes.MaturityEvaluationImport,
              breadcrumb: t('nav:page.maturity_evaluation_import'),
              element: <MaturityEvaluationImportScreen />,
            },
            {
              path: AppRoutes.MaturityEvaluationItem,
              breadcrumb: null,
              element: <MaturityEvaluationScreen />,
            },
            {
              path: AppRoutes.MaturityEvaluationItemTab,
              children: [
                {
                  index: true,
                  element: <MaturityEvaluationScreen />,
                  breadcrumb: null,
                },
              ],
            },
            {
              path: AppRoutes.MaturityEvaluationAssign,
              breadcrumb: t('nav:page.maturity_evaluation_assign'),
              element: <MaturityEvaluationAssignScreen />,
            },
          ],
        },
      ],
    },
    {
      path: AppRoutes.Home,
      element: (
        <ProOnlyGuard>
          <ProjectLayout />
        </ProOnlyGuard>
      ),
      children: [
        {
          path: AppRoutes.Settings,
          breadcrumb: null,
          children: [
            {
              path: AppRoutes.SettingsCoach,
              element: <CoachsSettingsScreen />,
              breadcrumb: t('nav:page.coach_management'),
            },
            {
              path: AppRoutes.SettingsCompany,
              element: <CompanySettingsScreen />,
              breadcrumb: t('nav:page.company_management'),
            },
            {
              path: AppRoutes.SettingsTemplates,
              element: <TemplatesSettingsScreen />,
              breadcrumb: t('nav:page.templates_management'),
            },
            {
              path: AppRoutes.ActionTemplateEdit,
              element: <ProActionEditScreen />,
            },
          ],
        },
      ],
    },
  ];
};
