import { CompanyFieldsFragment, CompanyType } from 'graphql/generated';
import React from 'react';
import { Coaches } from './Coaches';
import { Employees } from './Employees';

export function Users({ company }: { company: CompanyFieldsFragment }) {
  return (
    <div className="space-y-8 divide-y divide-gray-100">
      <Coaches company={company} />
      {company.type !== CompanyType.Template && <Employees company={company} />}
    </div>
  );
}
