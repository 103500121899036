import React, { useState } from 'react';
import { useTranslation } from '@hooks/useTranslation';
import {
  DeleteSurveyButtonFragment,
  DuplicateSurveyButtonFragment,
  ProSurveyListScreenDocument,
  SurveyContextualMenu_SurveysDocument,
  SurveyContextualMenuFragment,
  useSurveyContextualMenu_DeleteMutation,
  useSurveyDuplicateMutation,
} from '../../../graphql/generated';
import { useToast } from '../../layout/Toast';
import { Loader } from '../../generic/Loader';
import { generatePath, NavLink } from 'react-router-dom';
import { SURVEY_TABS } from './ProSurvey';
import { useProjectContext } from '../../../providers/ProjectContextProvider';
import {
  ConfirmationModal,
  ConfirmationModalType,
} from '../../modals/ConfirmationModal';
import { useModal } from '../../layout/Modal';
import {
  CopyIcon,
  DeleteIcon,
  EditIcon,
  EyeIcon,
  ScaleIcon,
  SettingsIcon,
  ShareIcon,
} from '../../icons';
import { ContextualMenu } from '../../generic/ContextualMenu';
import { AppRoutes } from '../../../screens/AppRoutes';

export function SurveyContextualMenu({
  survey,
}: {
  survey: SurveyContextualMenuFragment;
}) {
  const { t } = useTranslation();

  return (
    <ContextualMenu>
      <NavLink
        to={generatePath(AppRoutes.SurveyTab, {
          surveyId: survey.id,
          tab: SURVEY_TABS.SURVEY_CONTENT,
        })}
        className="contextual-menu-item button"
      >
        <EditIcon className="w-3 h-3" />
        <div>{t('survey.contextualMenu.content')}</div>
      </NavLink>
      <NavLink
        to={generatePath(AppRoutes.SurveyTab, {
          surveyId: survey.id,
          tab: SURVEY_TABS.SURVEY_SHARE,
        })}
        className="contextual-menu-item button"
      >
        <ShareIcon className="w-3 h-3" />
        <div>{t('survey.contextualMenu.share')}</div>
      </NavLink>
      <NavLink
        to={generatePath(AppRoutes.SurveyTab, {
          surveyId: survey.id,
          tab: SURVEY_TABS.SURVEY_TRACKING_RESPONSES,
        })}
        className="contextual-menu-item button"
      >
        <EyeIcon className="w-3 h-3" />
        <div>{t('survey.contextualMenu.follow')}</div>
      </NavLink>
      <NavLink
        to={generatePath(AppRoutes.SurveyTab, {
          surveyId: survey.id,
          tab: SURVEY_TABS.SURVEY_RESULTS,
        })}
        className="contextual-menu-item button"
      >
        <ScaleIcon className="w-3 h-3" />
        <div>{t('survey.contextualMenu.results')}</div>
      </NavLink>
      <NavLink
        to={generatePath(AppRoutes.SurveyTab, {
          surveyId: survey.id,
          tab: SURVEY_TABS.SETTINGS,
        })}
        className="contextual-menu-item button"
      >
        <SettingsIcon className="w-3 h-3" />
        <div>{t('survey.contextualMenu.settings')}</div>
      </NavLink>
      <hr className="border-t h-1 w-full mt-1 min-w-40" />
      <DuplicateSurveyButton survey={survey} />
      <DeleteSurveyButton survey={survey} />
    </ContextualMenu>
  );
}

function DuplicateSurveyButton({
  survey,
}: {
  survey: DuplicateSurveyButtonFragment;
}) {
  const toast = useToast();
  const projectContext = useProjectContext();

  const [duplicateSurveyMutation] = useSurveyDuplicateMutation({
    refetchQueries: () => [
      {
        query: ProSurveyListScreenDocument,
        variables: {
          companyId: projectContext?.company?.id || '',
        },
      },
    ],
  });
  const [isUpdating, setIsUpdating] = useState(false);

  const duplicate = () => {
    setIsUpdating(true);
    duplicateSurveyMutation({
      variables: {
        id: survey.id,
      },
    })
      .then(() => {
        toast.openToastWithMessage('Le questionnaire a bien été dupliqué');
      })
      .catch((err) => console.error(err))
      .finally(() => setIsUpdating(false));
  };

  return (
    <button
      className="contextual-menu-item"
      disabled={isUpdating}
      onClick={duplicate}
    >
      {isUpdating ? <Loader /> : <CopyIcon />}
      <div>Dupliquer</div>
    </button>
  );
}

function DeleteSurveyButton({
  survey,
}: {
  survey: DeleteSurveyButtonFragment;
}) {
  const toast = useToast();
  const modal = useModal();
  const projectContext = useProjectContext();

  const [deleteSurveyMutation] = useSurveyContextualMenu_DeleteMutation({
    refetchQueries: () => [
      {
        query: SurveyContextualMenu_SurveysDocument,
        variables: {
          companyId: projectContext?.company?.id || '',
        },
        fetchPolicy: 'network-only',
      },
    ],
  });
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteSurvey = () => {
    setIsDeleting(true);
    deleteSurveyMutation({
      variables: {
        id: survey.id,
      },
    })
      .then(() => {
        toast.openToastWithMessage('Le questionnaire a bien été supprimé');
      })
      .catch((err) => console.error(err))
      .finally(() => setIsDeleting(false));
  };

  return (
    <button
      className="contextual-menu-item"
      disabled={isDeleting}
      onClick={() =>
        modal.openModalWithComponent(
          <ConfirmationModal
            message="Cela supprimera également les réponses et analyse liés à cette consultation. Cette opération est irréversible !"
            callbackIfConfirmed={deleteSurvey}
            type={ConfirmationModalType.Danger}
          />,
          `Supprimer la consultation`,
        )
      }
    >
      {isDeleting ? <Loader /> : <DeleteIcon />}
      <div>Supprimer</div>
    </button>
  );
}
