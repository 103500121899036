import {
  MaturityEvaluationPreviewFragment,
  MaturityEvaluationsListScreenDocument,
  useCreateFromPreviewMaturityEvaluationTemplateMutation,
} from '../../../../graphql/generated';
import { useModal } from '../../../layout/Modal';
import { useToast } from '../../../layout/Toast';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { ChevronRightIcon } from '../../../icons';
import { Loader } from '../../../generic/Loader';
import { useTranslation } from 'react-i18next';
import { MaturityEvaluationPreview } from './MaturityEvaluationPreview';
import { AppRoutes } from '../../../../screens/AppRoutes';

export function PreviewMaturityEvaluationTemplate({
  maturityEvaluationTemplate,
  setPreviewMaturityEvaluationTemplate,
}: {
  maturityEvaluationTemplate: MaturityEvaluationPreviewFragment;
  setPreviewMaturityEvaluationTemplate: (
    maturityEvaluationTemplate: MaturityEvaluationPreviewFragment | null,
  ) => void;
}) {
  const { t } = useTranslation();
  const modal = useModal();
  const toast = useToast();
  const projectContext = useProjectContext();
  const navigate = useNavigate();

  const [maturityEvaluationCreateFromTemplateMutation] =
    useCreateFromPreviewMaturityEvaluationTemplateMutation();
  const [isAdding, setIsAdding] = useState(false);
  const add = () => {
    setIsAdding(true);

    maturityEvaluationCreateFromTemplateMutation({
      variables: {
        input: {
          surveyId: maturityEvaluationTemplate.id,
          companyId: projectContext?.company?.id || '',
        },
      },
      refetchQueries: [
        {
          query: MaturityEvaluationsListScreenDocument,
          variables: {
            companyId: projectContext?.company?.id || '',
          },
        },
      ],
    })
      .then((result) => {
        toast.openToastWithMessage(t('toast:maturity.create.success'));
        modal.closeModal();
        // Goto the maturityEvaluation list page
        // Hack : go to maturityEvaluation detail page and go back to list, to refresh the list
        navigate(
          `${AppRoutes.MaturityEvaluations}/${result.data?.maturityEvaluationCreateFromTemplate.id}`,
        );
        navigate(AppRoutes.MaturityEvaluations);
      })
      .catch((error) => {
        toast.openToastWithError(error.message);
      })
      .finally(() => {
        setIsAdding(false);
      });
  };

  return (
    <div className="flex flex-col w-full h-full">
      <div className="bg-white flex items-center gap-2 p-4 justify-between sticky top-0 z-50 border-b border-gray-100">
        <button
          className="tertiary bg-transparent text-gray-100"
          onClick={() => setPreviewMaturityEvaluationTemplate(null)}
        >
          <ChevronRightIcon className="w-4 h-4 transform rotate-180" />
          <span>{t('maturity.templateModal.backToModels')}</span>
        </button>
        <h4 className="text-center grow">
          {t('maturity.templateModal.evaluationAdd')}
        </h4>
        <button className="primary purple" onClick={add} disabled={isAdding}>
          {isAdding && <Loader />}
          <span>{t('maturity.templateModal.useTemplate')}</span>
        </button>
      </div>
      <MaturityEvaluationPreview
        maturityEvaluation={maturityEvaluationTemplate}
      />
    </div>
  );
}
