import {
  ActionDocument,
  CompanyDocument,
  useCreateChecklistButtonMutation,
} from '../../graphql/generated';
import React, { useState } from 'react';
import { Loader } from '../generic/Loader';
import { useProjectContext } from '../../providers/ProjectContextProvider';
import { PlusIcon } from '../icons';

export function CreateChecklistButton({
  actionId,
  companyId,
}: {
  actionId?: string;
  companyId?: string;
}) {
  const projectContext = useProjectContext();

  // Refetch query: depending on checklist creation, we need to refetch the action or the company
  const refetchQueries = [];
  if (actionId) {
    refetchQueries.push({
      query: ActionDocument,
      variables: {
        id: actionId,
      },
    });
  }
  if (companyId) {
    refetchQueries.push({
      query: CompanyDocument,
      variables: {
        id: companyId,
      },
    });
  }

  const [createChecklistMutation] = useCreateChecklistButtonMutation({
    refetchQueries: refetchQueries,
  });
  const [isLoading, setIsLoading] = useState(false);

  const createChecklist = () => {
    setIsLoading(true);
    createChecklistMutation({
      variables: {
        input: {
          actionId: actionId || undefined,
          projectId: companyId || undefined,
        },
      },
    })
      .then(() => {
        if (companyId && projectContext) {
          // If we are creating a checklist for a company, we need to update the company
          projectContext.updateCompany(companyId, false);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <button
      onClick={createChecklist}
      className="primary small purple"
      disabled={isLoading}
    >
      {isLoading ? <Loader /> : <PlusIcon />}
      <span>Créer une liste de tâches</span>
    </button>
  );
}
