import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Cookies from 'js-cookie';
import { CompanyFieldsFragment, useCompanyQuery } from '../graphql/generated';
import { useLocation, useNavigate } from 'react-router-dom';
import { useToast } from '../components/layout/Toast';
import clsx from 'clsx';
import { useCurrentUser } from './CurrentUserProvider';
import { isCoachUser } from '../services/CompanyService';
import { AppRoutes } from '../screens/AppRoutes';

export type ProjectContextType = {
  company: CompanyFieldsFragment | null;
  updateCompany: (
    companyId: string,
    redirectToHomePageAfterUpdate: boolean,
  ) => void;
  refreshCompany: () => void;
  isLoadingCompany: boolean;
};
const ProjectContext = createContext<ProjectContextType | null>(null);
export const useProjectContext = () => useContext(ProjectContext);

export function ProjectContextProvider({ children }: { children: ReactNode }) {
  const toast = useToast();
  const [company, setCompany] = useState<CompanyFieldsFragment | null>(null);
  const [isLoadingCompany, setIsLoadingCompany] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const currentUser = useCurrentUser();
  const isCoach = isCoachUser(currentUser);
  // Reload previous company from cookie if it exists
  const ACTIVE_COMPANY_COOKIE_NAME = 'activeCompanyId';
  if (!isCoach && currentUser?.company?.id) {
    Cookies.set(ACTIVE_COMPANY_COOKIE_NAME, currentUser?.company?.id);
  }

  const [activeCompanyId, setActiveCompanyId] = useState<string | null>(
    Cookies.get(ACTIVE_COMPANY_COOKIE_NAME) || null,
  );
  const [isFirstRender, setIsFirstRender] = useState(true);

  const { refetch } = useCompanyQuery({
    variables: {
      id: activeCompanyId || '',
    },
    skip: !activeCompanyId,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      // const excludedPaths = [];
      // if (excludedPaths.includes(location.pathname as ProRoutes)) {
      //   setIsLoadingCompany(false);
      //   return;
      // }
      if (activeCompanyId) {
        setIsLoadingCompany(true);
        refetch({ id: activeCompanyId })
          .then((res) => {
            setCompany(res.data?.company || null);
          })
          .catch((err) => {
            // Company does not exist or user does not have access anymore
            console.error(err);
            setActiveCompanyId(null);
            Cookies.remove(ACTIVE_COMPANY_COOKIE_NAME);
          })
          .finally(() => setIsLoadingCompany(false));
      } else {
        setIsLoadingCompany(false);
      }
    }
  }, [activeCompanyId, refetch, location.pathname, isFirstRender]);

  const updateCompany = useCallback(
    (companyId: string, redirectToHomePageAfterUpdate: boolean) => {
      setIsLoadingCompany(true);
      refetch({ id: companyId })
        .then((res) => {
          setCompany(res.data?.company || null);
          Cookies.set(ACTIVE_COMPANY_COOKIE_NAME, companyId, { expires: 7 });
          if (redirectToHomePageAfterUpdate) {
            navigate(AppRoutes.Project);
          }
        })
        .catch((err) => {
          console.error(err);
          toast.openToastWithError(err.message);
          setCompany(null);
          Cookies.remove(ACTIVE_COMPANY_COOKIE_NAME);
          navigate(AppRoutes.Home);
        })
        .finally(() => setIsLoadingCompany(false));
    },
    [navigate, refetch, toast],
  );

  const refreshCompany = useCallback(() => {
    if (company?.id) {
      refetch({
        id: company.id,
      })
        .then((res) => {
          setCompany(res.data.company);
        })
        .catch((err) => console.error(err));
    }
  }, [company, refetch]);

  useEffect(() => {
    if (currentUser && !isCoach && currentUser.company && !activeCompanyId) {
      updateCompany(currentUser.company.id, false);
    }
  }, [currentUser, isCoach, updateCompany]);

  const contextValue = useMemo(
    () => ({
      company,
      updateCompany,
      refreshCompany,
      isLoadingCompany,
    }),
    [
      company,
      updateCompany,
      refreshCompany,
      isLoadingCompany,
    ],
  );

  return (
    <ProjectContext.Provider value={contextValue}>
      <div className={clsx('relative', isLoadingCompany && 'opacity-50')}>
        {children}
      </div>
    </ProjectContext.Provider>
  );
}
