import React from 'react';
import { StandardTopicTag_TopicFragment } from '../../../../graphql/cms/generated';
import { StandardEnum } from '../../../../graphql/generated';
import { EuIcon, Iso26000Icon } from '../../../icons';
import clsx from 'clsx';

export function StandardTopicTag({
  topic,
}: {
  topic: StandardTopicTag_TopicFragment;
}) {
  switch (topic.standard?.slug) {
    case StandardEnum.Csrd:
    case 'csrd':
      return (
        <StandardTopicStyledTag
          topic={topic}
          textCssClass="text-blue-900"
          backgroundCssClass="bg-gray-50"
          icon={
            <div className="bg-blue-900 rounded px-1.5 py-0.5">
              <EuIcon className="w-3.5 h-3.5 text-yellow-500" />
            </div>
          }
        />
      );
    case StandardEnum.Iso26000:
    case 'iso-26000':
      return (
        <StandardTopicStyledTag
          topic={topic}
          textCssClass="text-red-900"
          backgroundCssClass="bg-red-50"
          icon={
            <div className="bg-red-900 rounded px-1.5 py-0">
              <Iso26000Icon className="w-5 h-5 text-red-50" />
            </div>
          }
        />
      );
    default:
      return (
        <StandardTopicStyledTag
          topic={topic}
          textCssClass="text-blue-900"
          backgroundCssClass="bg-gray-50"
        />
      );
  }
}

function StandardTopicStyledTag({
  topic,
  textCssClass,
  backgroundCssClass,
  icon,
}: {
  topic: StandardTopicTag_TopicFragment;
  textCssClass: string;
  backgroundCssClass: string;
  icon?: React.ReactNode;
}) {
  return (
    <div
      className={clsx('tag font-semibold', textCssClass, backgroundCssClass)}
    >
      {icon}
      <div
        className="xl:max-w-[20rem] max-w-[10rem] truncate text-ellipsis"
        title={topic.name}
      >
        {topic.name}
      </div>
    </div>
  );
}
