import {
  CatalystFieldsFragment,
  useActionCreateMutation,
} from '../../../graphql/generated';
import React, { Fragment, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { useModal } from '../../layout/Modal';
import { Loader } from '../../generic/Loader';
import { ActionTemplateGalleryModal } from './ActionTemplateGalleryModal';
import { generatePath, useNavigate } from 'react-router-dom';
import {
  ChevronDownIcon,
  CreateNewIcon,
  CreateTemplateIcon,
  PlusIcon,
} from '../../icons';
import { AppRoutes } from '../../../screens/AppRoutes';

export function AddActionMenu({
  catalyst,
}: {
  catalyst: CatalystFieldsFragment;
}) {
  const modal = useModal();
  const navigate = useNavigate();

  const [addAction] = useActionCreateMutation();
  const [isAdding, setIsAdding] = useState(false);
  const add = () => {
    setIsAdding(true);
    addAction({
      variables: {
        input: {
          roadmapId: catalyst.roadmap.id,
          catalystId: catalyst.id,
          companyId: catalyst.roadmap.company.id,
        },
      },
    })
      .then((res) => {
        if (res.data?.actionCreate) {
          navigate(
            generatePath(AppRoutes.RoadmapActionEdit, {
              actionId: res.data.actionCreate.id,
            }),
          );
        }
      })
      .catch((err) => console.error(err))
      .finally(() => setIsAdding(false));
  };

  const openActionTemplateGalleryModal = () => {
    modal.openModalWithComponent(
      <ActionTemplateGalleryModal catalyst={catalyst} />,
      null,
      true,
      false,
      'w-10/12',
    );
  };

  return (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button className="secondary purple">
            <PlusIcon className="w-4 h-4" />
            <span>Ajouter une action</span>
            <ChevronDownIcon className="w-4 h-4" />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="z-50 absolute bg-white top-0 left-0 transform translate-y-12 w-56">
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="flex flex-col items-start p-1">
                  <button
                    className="contextual-menu-item"
                    onClick={() => {
                      openActionTemplateGalleryModal();
                      close();
                    }}
                  >
                    <CreateTemplateIcon className="w-4 h-4" />
                    <span>Depuis un modèle</span>
                  </button>
                  <button className="contextual-menu-item" onClick={add}>
                    {isAdding ? (
                      <Loader />
                    ) : (
                      <CreateNewIcon className="w-4 h-4" />
                    )}
                    <span>Vide</span>
                  </button>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
