import React, { useState } from 'react';
import { useTranslation } from '@hooks/useTranslation';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import { PageTitle } from '../../../../components/nav/PageTitle';
import { useCurrentUser } from '../../../../providers/CurrentUserProvider';
import { PrioritizationMatrixHeader } from '../../../../components/publications/prioritizationMatrix/PrioritizationMatrixHeader';
import { PrioritizationMatrix } from '../../../../components/publications/prioritizationMatrix/PrioritizationMatrix';
import { PrioritizationMatrixEditForm } from '../../../../components/project/publications/prioritizationMatrix/PrioritizationMatrixEditForm';
import { LoaderFullscreen } from '../../../../components/layout/Loader';
import {
  MessageBox,
  MessageBoxType,
} from '../../../../components/layout/MessageBox';
import { EditIcon, XIcon } from '../../../../components/icons';
import { useProPrioritizationMatrixScreenQuery } from '../../../../graphql/generated';
import { useNavContext } from '../../../../providers/NavContextProvider';
import { withProjectContext } from '../../../../providers/withProjectContext';

export const PrioritizationMatrixScreen = withProjectContext(
  () => {
    const { t } = useTranslation();
    const [isEditMode, setIsEditMode] = useState(false);

    const projectContext = useProjectContext();

    const stakesQuery = useProPrioritizationMatrixScreenQuery({
      variables: {
        referentialId: projectContext?.company?.referential?.id || '',
        companyId: projectContext?.company?.id || '',
      },
      skip: !projectContext?.company?.id,
      fetchPolicy: 'network-only',
    });

    const stakes = stakesQuery.data?.computeMaturityScores;
    const priorities =
      stakesQuery.data?.computeSimpleMaterialitiesFinancial ?? [];

    if (projectContext?.isLoadingCompany || stakesQuery.loading) {
      return <LoaderFullscreen />;
    }

    if (!projectContext?.company?.referential?.id) {
      return (
        <MessageBox type={MessageBoxType.Error}>
          {t('screen.prioritizationMatrix.noReferential')}
        </MessageBox>
      );
    }

    return (
      (stakes && (
        <>
          <PageTitle title={t('screen.prioritizationMatrix.title')} />
          <ScreenTopBar isEditMode={isEditMode} setIsEditMode={setIsEditMode} />
          {isEditMode ? (
            <>
              <div className="">
                <PrioritizationMatrixHeader />
              </div>
              <div className="main-content space-y-16 py-16 w-full">
                <div>
                  <PrioritizationMatrix
                    stakes={stakes}
                    priorities={priorities}
                  />
                </div>
                <div>
                  <PrioritizationMatrixEditForm stakes={stakes} />
                </div>
              </div>
            </>
          ) : (
            <>
              <PrioritizationMatrixHeader />
              <div className="p-8 flex flex-col gap-8 main-content w-full divide-y divide-gray-100">
                <PrioritizationMatrix stakes={stakes} priorities={priorities} />
              </div>
            </>
          )}
        </>
      )) || (
        <MessageBox type={MessageBoxType.Error}>
          {t('screen.prioritizationMatrix.noStakes')}
        </MessageBox>
      )
    );
  },
  { requiredCompany: true },
);

function ScreenTopBar({
                        isEditMode,
                        setIsEditMode,
                      }: {
  isEditMode: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
}) {
  const { t } = useTranslation();
  const navContext = useNavContext();
  const currentUser = useCurrentUser();

  const editMode = () => {
    setIsEditMode(true);
    navContext?.setIsNavCollapsed(true);
  };

  const closeEditMode = () => {
    setIsEditMode(false);
    navContext?.setIsNavCollapsed(false);
  };

  if (isEditMode) {
    return (
      <div className="py-4 px-8 flex items-center gap-4 justify-between">
        <button className="tertiary" onClick={closeEditMode}>
          <XIcon />
        </button>
        <h1 className="title-h5 grow text-center">
          {t('screen.prioritizationMatrix.titleEdit')}
        </h1>
        <div></div>
      </div>
    );
  } else {
    return (
      <div className="py-4 px-8 flex items-center gap-4 justify-between">
        <h1 className="title-h5">{t('screen.prioritizationMatrix.title')}</h1>
        <div className="flex items-center gap-4">
          <button className="primary purple" onClick={editMode}>
            <EditIcon className="w-4 h-4" />
            <span>{t('screen.prioritizationMatrix.editButton')}</span>
          </button>
        </div>
      </div>
    );
  }
}
